import { JsonPipe } from "@angular/common";
import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { firstValueFrom, Subscription } from "rxjs";
import { ActionEnhanced } from "src/app/models/action/action.model";
import { ChartsAssigneeAction } from "src/app/models/charts/charts-assignees.model";
import { ThemeEnhanced, ThemeStyleModel } from "src/app/models/theme/theme.model";
import { BreadcrumbsComponent } from "src/app/shared/breadcrumbs/breadcrumbs.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { ActionsListService } from "../../actions/services/actions-list.service";
import { InspectionCategoriesListService } from "../../categories/services/inspection-categories-list.service";
import { ChartsService } from "../../inspections/services/charts.service";
import { InspectionDetailService } from "../../inspections/services/inspection-detail.service";
import { SignOffListService } from "../../inspections/services/sign-off-list.service";
import { SupplementaryService } from "../../inspections/supplementary/services/supplementary.service";
import { inspectionItemsListService } from "../../items/services/inspection-items-list.service";
import { ItemPhotosService } from "../../items/services/item-photos.service";
import { ClientDetailService } from "../../workspaces/current-workspace/manage-clients/services/client-detail.service";
import { SiteDetailService } from "../../workspaces/current-workspace/manage-sites/services/site-detail.service";
import { ThemeStylesAndSettingsService } from "../../workspaces/current-workspace/manage-themes/services/theme-styles-and-settings.service";
import { ThemesListService } from "../../workspaces/current-workspace/manage-themes/services/themes-list.service";
import { WorkspacesService } from "../../workspaces/services/workspaces.service";
import { ReportContentWrapperComponent } from "../report-content/report-content-wrapper/report-content-wrapper.component";
import { ReportSettingsWrapperComponent } from "../report-settings/report-settings-wrapper/report-settings-wrapper.component";
import { ReportsService } from "../services/reports.service";

@Component({
  selector: "report-wrapper",
  standalone: true,
  imports: [JsonPipe, ReportSettingsWrapperComponent, LoadingSpinnerComponent, ReportSettingsWrapperComponent, BreadcrumbsComponent, ReportContentWrapperComponent],
  templateUrl: "./report-wrapper.component.html",
  styleUrl: "./report-wrapper.component.scss",
})
export class ReportWrapperComponent implements OnInit, OnDestroy {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set folderId(value: string) {
    this._folderId = value;
  }

  get folderId(): string {
    return this._folderId;
  }

  @Input({ required: true }) set inspectionId(value: string) {
    this._inspectionId = value;
  }

  get inspectionId(): string {
    return this._inspectionId;
  }

  // Services
  private inspectionDetailService = inject(InspectionDetailService);
  private inspectionCategoriesListService = inject(InspectionCategoriesListService);
  private signOffListService = inject(SignOffListService);
  private inspectionItemsListService = inject(inspectionItemsListService);
  private actionsListService = inject(ActionsListService);
  private itemPhotosService = inject(ItemPhotosService);
  private chartsService = inject(ChartsService);
  private supplementaryService = inject(SupplementaryService);
  private clientDetailService = inject(ClientDetailService);
  private siteDetailService = inject(SiteDetailService);
  private workspacesService = inject(WorkspacesService);
  private themesListService = inject(ThemesListService);
  private themeStylesAndSettingsService = inject(ThemeStylesAndSettingsService);
  public reportsService = inject(ReportsService);

  // Properties
  private _workspaceId!: string;
  private _folderId!: string;
  private _inspectionId!: string;
  private _reportSettingsSubscription!: Subscription;
  private _themeSettingsFormSubscription!: Subscription;
  public dataReady: boolean = false;
  public themes!: ThemeEnhanced[];
  private preferredTheme!: string;

  ngOnInit(): void {
    this.performReportQuery();
    this.subscribeToReportSettings();
  }

  ngOnDestroy(): void {
    this._reportSettingsSubscription?.unsubscribe();
    this._themeSettingsFormSubscription?.unsubscribe();
  }

  private async performReportQuery(): Promise<void> {
    try {
      // Get themes first
      const themes = await firstValueFrom(this.themesListService.getThemesList$(this.workspaceId));
      this.themes = themes;

      // Find the appropriate theme to use
      let themeToUse: ThemeEnhanced | null = null;
      themes.forEach((theme) => {
        if ((this.preferredTheme && theme.id === this.preferredTheme) || (!this.preferredTheme && theme.id === "default")) {
          themeToUse = theme;
        }
      });

      // Set the theme if found
      if (themeToUse) {
        this.reportsService.themeDetails.next(themeToUse);
      }

      // Get theme styles
      const themeStyles = await firstValueFrom(this.themeStylesAndSettingsService.getThemeStyles$());
      this.reportsService.themeStyles = themeStyles;

      // Set up theme settings subscription
      this._themeSettingsFormSubscription = this.reportsService.themeDetails.subscribe((theme) => {
        this.clearAllThemeStyles();
        this.applyNewStyles(theme);
      });

      // Get inspection since other queries depend on it (client / site id's)
      const inspection = await firstValueFrom(this.inspectionDetailService.getInspectionDoc$(this.workspaceId, this.inspectionId));

      if (inspection) {
        // Set inspection data
        this.reportsService.reportData.inspection = inspection;

        // Get workspace data
        const workspace = await firstValueFrom(this.workspacesService.getWorkspaceDoc$(this.workspaceId));
        if (workspace) {
          this.reportsService.reportData.workspace = workspace;
        }

        // Get categories and  their items
        const categories = await firstValueFrom(this.inspectionCategoriesListService.getEnabledCategories$(this.workspaceId, this.inspectionId));

        if (categories) {
          this.reportsService.reportData.categories = categories;

          // Clear items and actions arrays before processing
          this.reportsService.reportData.items = [];
          this.reportsService.reportData.actions = [];
          this.reportsService.actionsFiltered = [];
          this.reportsService.reportData.chartsActions = [];
          this.reportsService.reportData.chartsAssignees = [];
          this.reportsService.reportData.actionAssignees = [];

          // Process each category's items
          await Promise.all(
            categories.map(async (category) => {
              if (category.items_count > 0) {
                const items = await firstValueFrom(this.inspectionItemsListService.getItemsList$(this.workspaceId, this.inspectionId, category.id));

                // Process each item's actions and photos
                await Promise.all(
                  items.map(async (item) => {
                    if (item.actions_count > 0) {
                      const actionsList = await firstValueFrom(this.actionsListService.getItemActions$(this.workspaceId, item.id));
                      this.reportsService.reportData.actions = [];
                      this.reportsService.actionsFiltered = [];
                      this.reportsService.reportData.chartsActions = [];
                      this.reportsService.reportData.chartsAssignees = [];
                      const actions: ActionEnhanced[] = [];
                      const actionAssignees: ChartsAssigneeAction[] = [];
                      actionsList.forEach((action) => {
                        const data = action as ActionEnhanced;
                        const actionAssignee = action as ChartsAssigneeAction;
                        const priority = data.priority;
                        const assigneeId = data.assignee_id;
                        actions.push(data);
                        actionAssignees.push(actionAssignee);
                        if (priority !== undefined) {
                          // Check if the priority value already exists in the array
                          if (this.reportsService.reportSettings.priorities.indexOf(priority) === -1) {
                            // If it doesn't exist, push the priority value
                            this.reportsService.reportSettings.priorities.push(priority);
                          }
                        } else if (this.reportsService.reportSettings.priorities.indexOf(-1) === -1) {
                          // Push -1 if priority is undefined and -1 is not already in the array
                          this.reportsService.reportSettings.priorities.push(-1);
                        }

                        // Check if assigneeId is not undefined
                        if (assigneeId !== undefined) {
                          // Check if assigneeId is not already in the assignee_ids array
                          if (this.reportsService.reportSettings.assignee_ids.indexOf(assigneeId) === -1) {
                            // If not in the array, push assigneeId into assignee_ids
                            this.reportsService.reportSettings.assignee_ids.push(assigneeId);
                          }

                          // Push the entire data object into actionAssignees array
                          this.reportsService.reportData.actionAssignees.push(data);
                        } else if (this.reportsService.reportSettings.assignee_ids.indexOf("no-assignee") === -1) {
                          // If assigneeId is undefined, check if 'no-assignee' is not already in the assignee_ids array

                          // If 'no-assignee' is not in the array, push 'no-assignee' into assignee_ids
                          this.reportsService.reportSettings.assignee_ids.push("no-assignee");
                        }
                      });

                      this.reportsService.reportData.actions = [...this.reportsService.reportData.actions, ...actions];
                      this.reportsService.actionsFiltered = this.reportsService.reportData.actions.filter((action) => this.reportsService.shouldDisplayAction(action));
                      this.reportsService.reportData.chartsActions = this.chartsService.filterActionResults(this.reportsService.reportData.actions);
                      this.reportsService.reportData.chartsAssignees = this.chartsService.filterActionByAssignee(actionAssignees);
                    }

                    if (item.photos_count > 0) {
                      this.reportsService.reportData.photos = [];
                      const photos = await firstValueFrom(this.itemPhotosService.getPhotosList$(this.workspaceId, this.inspectionId, item.id));
                      this.reportsService.reportData.photos = [...this.reportsService.reportData.photos, ...photos];
                    }
                  })
                );

                // Add items to report data
                this.reportsService.reportData.items = [...this.reportsService.reportData.items, ...items];
              }
            })
          );

          // Process charts after all items are loaded
          // this.reportsService.reportData.chartsTests = this.chartsService.filterChartsTestResults(this.reportsService.reportData.items);
          this.reportsService.reportData.chartsTests = this.chartsService.filterChartsTestResults(this.reportsService.reportData.items).testResults;
        }

        // Get signatures
        const signatures = await firstValueFrom(this.signOffListService.getSignaturesList$(this.workspaceId, this.inspectionId));
        this.reportsService.reportData.signatures = signatures;

        // Get introduction and summary
        const introduction = await firstValueFrom(this.supplementaryService.getSupplementaryList$(this.workspaceId, this.inspectionId, "introduction"));
        this.reportsService.reportData.introduction = introduction[0];

        const summary = await firstValueFrom(this.supplementaryService.getSupplementaryList$(this.workspaceId, this.inspectionId, "summary"));
        this.reportsService.reportData.summary = summary[0];

        // Get client and site if IDs exist
        if (inspection.client_id) {
          const client = await firstValueFrom(this.clientDetailService.getClientDoc$(this.workspaceId, inspection.client_id));
          if (client) {
            this.reportsService.reportData.client = client;
          }
        }

        if (inspection.site_id) {
          const site = await firstValueFrom(this.siteDetailService.getSiteDoc$(this.workspaceId, inspection.site_id));
          if (site) {
            this.reportsService.reportData.site = site;
          }
        }

        // Set loading boolean when data is ready
        this.dataReady = true;
      }
    } catch (error) {
      console.error("Failed to load report data:", error);
    }
  }

  private subscribeToReportSettings() {
    // Subscribe to any changes the user makes to the report settings filters
    this._reportSettingsSubscription = this.reportsService.reportSettings$.subscribe(() => {
      // If a value in the filters have been changed, we want the actionsFiltered array to be reset to match all actions
      this.reportsService.actionsFiltered = this.reportsService.reportData.actions;

      // When we notice a change to the settings, loop through all the original actions and call...
      // ...shouldDisplayAction() to determine if the action should be displayed or not
      this.reportsService.reportData.actions.forEach((action) => {
        this.reportsService.shouldDisplayAction(action);
      });
    });
  }

  private mapToChartsAssigneeAction(actions: ActionEnhanced[]): ChartsAssigneeAction[] {
    return actions.map((action) => ({
      assignee_id: action.assignee_id || "no-assignee",
      assignee_name: action.assignee_name || "No Assignee",
      priority: action.priority,
    }));
  }

  /**
   * Apply New Styles
   * The `applyNewStyles` function is responsible for applying the CSS styles associated with a given theme
   * to specific sections or components of the application. It takes a `theme` object as an argument, which contains
   * style properties for various sections, and applies these styles using the `applyStyle` function.
   */
  applyNewStyles(theme: ThemeStyleModel) {
    const { styles_cover, styles_supplementary, styles_site, styles_category, styles_item, styles_photo, styles_action, styles_signature, styles_chart } = theme;

    this.applyStyle("Cover", styles_cover);
    this.applyStyle("Supplementary", styles_supplementary);
    this.applyStyle("Site", styles_site);
    this.applyStyle("Chart", styles_chart);
    this.applyStyle("Category", styles_category);
    this.applyStyle("Item", styles_item);
    this.applyStyle("Photo", styles_photo);
    this.applyStyle("Action", styles_action);
    this.applyStyle("Signature", styles_signature);
  }

  /**
   * Apply Style
   * The `applyStyle` function is responsible for applying a specific CSS style to a corresponding section or component
   * of the application. It takes the `propertyName` and `propertyValue` as arguments, where `propertyName` represents
   * the section or component, and `propertyValue` is the ID of the theme style to apply. It retrieves the matching
   * theme style and applies it using the `themeStylesAndSettingsService`.
   */
  applyStyle(propertyName: string, propertyValue: string) {
    const matchingThemeStyle = this.reportsService.themeStyles.find((style) => style.id === propertyValue);

    if (matchingThemeStyle) {
      switch (propertyName) {
        case "Cover":
          this.themeStylesAndSettingsService.applyCoverStyles(matchingThemeStyle.css);
          break;
        case "Supplementary":
          this.themeStylesAndSettingsService.applySupplementaryStyles(matchingThemeStyle.css);
          break;
        case "Site":
          this.themeStylesAndSettingsService.applySiteStyles(matchingThemeStyle.css);
          break;
        case "Category":
          this.themeStylesAndSettingsService.applyCategoryStyles(matchingThemeStyle.css);
          break;
        case "Chart":
          this.themeStylesAndSettingsService.applyChartStyles(matchingThemeStyle.css);
          break;
        case "Item":
          this.themeStylesAndSettingsService.applyItemStyles(matchingThemeStyle.css);
          break;
        case "Photo":
          this.themeStylesAndSettingsService.applyPhotoStyles(matchingThemeStyle.css);
          break;
        case "Action":
          this.themeStylesAndSettingsService.applyActionStyles(matchingThemeStyle.css);
          break;
        case "Signature":
          this.themeStylesAndSettingsService.applySignatureStyles(matchingThemeStyle.css);
          break;
        default:
          break;
      }
    } else {
      console.log(`No matching ThemeStyle found for ${propertyName} with value: ${propertyValue}`);
    }
  }

  /**
   * Clear All Theme Styles
   * The `clearAllThemeStyles` function is responsible for clearing all applied theme styles from the application.
   * It iterates through an array of style tags representing different sections or components and uses the
   * `themeStylesAndSettingsService` to clear the associated styles.
   */
  clearAllThemeStyles(): void {
    const styleTags = ["styles_coverCSS", "styles_siteCSS", "styles_introductionCSS", "styles_categoryCSS", "styles_itemCSS", "styles_photoCSS", "styles_actionCSS", "styles_summaryCSS", "styles_signatureCSS", "styles_chartCSS"];
    styleTags.forEach((styleTag) => {
      this.themeStylesAndSettingsService.clearStyles(styleTag);
    });
  }
}
