import { AsyncPipe } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { Auth, authState, isSignInWithEmailLink, signInWithEmailLink } from "@angular/fire/auth";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import { firstValueFrom, Observable } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { ProfileService } from "src/app/core/services/profile.service";
import { InfoViewStringsService } from "src/app/core/services/strings/info-view-strings.service";
import { extractErrorCode } from "src/app/core/util/extract-error-code";
import { extractErrorMessage } from "src/app/core/util/extract-error-message";
import { InfoViewFilter, InfoViewType } from "src/app/models/strings/strings.model";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { UserNameFormComponent } from "src/app/shared/user-name-form/user-name-form.component";

type ErrorInfo = {
  code: string;
  message: string;
};

@Component({
  selector: "login-confirmation",
  standalone: true,
  imports: [AsyncPipe, RouterModule, LoadingSpinnerComponent, UserNameFormComponent],
  templateUrl: "./login-confirmation.component.html",
  styleUrl: "./login-confirmation.component.scss",
})
export class LoginConfirmationComponent implements OnInit {
  private auth = inject(Auth);
  private router = inject(Router);
  private fb = inject(FormBuilder);
  private authService = inject(AuthService);
  private infoViewStringsService = inject(InfoViewStringsService);
  private profileService = inject(ProfileService);

  public newAccountForm = false;
  public signUpForm: FormGroup;
  public infoView: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.AddYourName);
  public user!: Observable<any>;
  public errorInfo!: ErrorInfo;

  constructor() {
    this.signUpForm = this.fb.group({
      name_first: ["", Validators.required],
      name_last: ["", Validators.required],
      terms: [false, Validators.requiredTrue],
      privacy: [false, Validators.requiredTrue],
    });
  }

  ngOnInit(): void {
    // Replace afAuth.authState with authState(this.auth)
    this.user = authState(this.auth);
    this.confirmSignInOrCreateNewAccount();
  }

  /**
   * Confirm sign in or create new account
   */
  async confirmSignInOrCreateNewAccount() {
    try {
      // Use isSignInWithEmailLink from the new Auth API
      if (isSignInWithEmailLink(this.auth, window.location.href)) {
        let email = this.authService.getEmailFromLocalStorage();
        const newAccount = this.authService.isNewAccountAccordingToStorage();

        // If missing email, prompt user to provide it
        if (!email) {
          email = window.prompt("Please provide your email for confirmation");
        }

        // Signin user and remove the email from localStorage
        if (email) {
          const result = await signInWithEmailLink(this.auth, email, window.location.href);
          const userId = result.user?.uid;
          if (userId) {
            const userDoc = await firstValueFrom(this.profileService.getUserProfile$(userId));
            if (userDoc) {
              if (userDoc.name_first === undefined && newAccount) {
                this.newAccountForm = true;
              } else {
                this.handleSuccessfullSignIn();
              }
            }
          }
        }
      }
    } catch (error) {
      this.errorInfo = {
        code: extractErrorCode(error),
        message: extractErrorMessage(error),
      };
    }
  }

  /**
   * Create User Doc
   */
  // async createUserDoc() {
  //   try {
  //     const currentUser = this.auth.currentUser;
  //     const email = this.authService.getEmailFromLocalStorage();
  //     if (currentUser && email) {
  //       this.authService.createUserDoc(this.signUpForm, email);
  //       this.handleSuccessfullSignIn();
  //     }
  //   } catch (error) {
  //     alert(error);
  //   }
  // }

  /**
   * Handle Successfull Sign In
   */
  handleSuccessfullSignIn() {
    this.authService.removeEmailFromLocalStorage();
    this.authService.removeNewAccountFromLocalStorage();
    this.router.navigate(["workspaces"]);
  }
}
