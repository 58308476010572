<form [formGroup]="itemForm">
  <fieldset>
    <div class="relative -space-y-px rounded-md border bg-white">
      @for (answerTestType of answerTestTypes; track $index; let last = $last; let i = $index) {
        <label
          [ngClass]="[currentAnswerTestType === answerTestType.id ? 'z-10 bg-brand_Primary/5' : 'border-gray-200', last ? 'border-none' : 'border-b']"
          (click)="setAnswerTestType(answerTestType.id)"
          class="relative flex cursor-pointer p-4 focus:outline-none"
          [for]="'test-type-' + answerTestType.title">
          <input
            type="radio"
            [value]="answerTestType.id"
            class="mt-0.5 h-4 w-4 shrink-0 cursor-pointer border-gray-300 text-brand_Primary focus:ring-brand_Primary"
            [attr.aria-labelledby]="'test-type-label-' + i"
            [attr.aria-describedby]="'test-type-description-' + i"
            formControlName="answer_type"
            [id]="'test-type-' + answerTestType.title" />
          <span class="ml-3 flex flex-col">
            <span [id]="'test-type-label-' + i" [ngClass]="currentAnswerTestType === answerTestType.id ? 'text-brand_Primary' : 'text-text_Title'" class="block text-sm font-medium">
              {{ answerTestType.title }}
            </span>
            <span [id]="'test-type-description-' + i" class="sr-only"> Select {{ answerTestType.title }} test type for this item </span>
          </span>
        </label>
      }
    </div>
  </fieldset>
</form>
