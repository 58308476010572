<div class="action-wrapper">
  <p class="action-header text-group-2">Actions</p>
  <ul>
    <li>
      <div class="action-li-wrapper">
        <div class="action-content">
          <div class="action-content-left">
            <p class="action-title text-group-3">1. Check number of people work</p>
            <div class="action-assignee-wrapper" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">
              <p class="action-assignee-name text-group-6">John Doe</p>

              <span>&#8226;</span>
              <p class="action-assignee-email text-group-6">{{ emailAddress }}</p>
            </div>
            <p class="action-date text-group-6" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">{{ themeStylesAndSettingsSerivce.demoDate | dateFormat: themeSettingsForm.value.dates_order : themeSettingsForm.value.dates_style }}</p>
          </div>
          <div class="action-content-right">
            <div class="action-priority text-group-5 action-priority-{{ actionsListService.priorityDict[0].priority }}">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
                  <circle cx="50" cy="50" r="44" fill="none" stroke-width="8" />
                  <circle cx="50" cy="50" r="16" />
                </svg>
                <p>{{ actionsListService.priorityDict[0].priority }}</p>
              </div>
            </div>
            <!-- <ng-template [ngIf]="action.complete; then complete; else incomplete"></ng-template> -->
            <div class="action-incomplete text-group-5">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
                  <circle cx="50" cy="50" r="48" fill="#B9C5CE"></circle>
                  <path d="M50 22 V62 M50 80V68" stroke="#F4F7FA" stroke-width="12"></path>
                </svg>
                <p>Incomplete</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="action-li-wrapper complete">
        <div class="action-content">
          <div class="action-content-left">
            <p class="action-title text-group-3">2. Check number of people work</p>
            <div class="action-assignee-wrapper" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">
              <p class="action-assignee-name text-group-6">John Doe</p>
              <span>&#8226;</span>
              <p class="action-assignee-email text-group-6">{{ emailAddress }}</p>
            </div>
            <p class="action-date text-group-6" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">{{ themeStylesAndSettingsSerivce.demoDate | dateFormat: themeSettingsForm.value.dates_order : themeSettingsForm.value.dates_style }}</p>
          </div>
          <div class="action-content-right">
            <div class="action-priority text-group-5 action-priority-{{ actionsListService.priorityDict[25].priority }}">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
                  <circle cx="50" cy="50" r="44" fill="none" stroke-width="8" />
                  <circle cx="50" cy="50" r="16" />
                </svg>
                <p>{{ actionsListService.priorityDict[25].priority }}</p>
              </div>
            </div>

            <div class="action-complete text-group-5">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
                  <circle cx="50" cy="50" r="48" fill="white"></circle>
                  <path d="M32 50 L46 62 L68 36" stroke="#61788D" stroke-width="10"></path>
                </svg>
                <p>Complete</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
