import { Routes } from "@angular/router";
import { RolePermissions } from "cip";
import { AuthGuard } from "./core/guards/auth.guard";
import { LimitationGuard } from "./core/guards/limitation.guard";
import { PendingChangesGuard } from "./core/guards/pending-changes.guard";
import { ProfileCompleteGuard } from "./core/guards/profile-complete.guard";
import { SigninGuard } from "./core/guards/signin.guard";
import { AccountConnectedDevicesComponent } from "./sections/account/account-connected-devices/account-connected-devices.component";
import { AccountDeleteComponent } from "./sections/account/account-delete/account-delete.component";
import { AccountEmailComponent } from "./sections/account/account-email/account-email.component";
import { AccountMyDataComponent } from "./sections/account/account-my-data/account-my-data.component";
import { AccountProfileComponent } from "./sections/account/account-profile/account-profile.component";
import { AccountSubscriptionComponent } from "./sections/account/account-subscription/account-subscription.component";
import { AccountWrapperComponent } from "./sections/account/account-wrapper/account-wrapper.component";
import { ActionEditWrapperComponent } from "./sections/actions/edit/action-edit-wrapper/action-edit-wrapper.component";
import { AnnotationsWrapperComponent } from "./sections/annotations/annotations-wrapper/annotations-wrapper.component";
import { AuditLogsListWrapperComponent } from "./sections/audit-logs/list/audit-logs-list-wrapper/audit-logs-list-wrapper.component";
import { AuditLogOverviewWrapperComponent } from "./sections/audit-logs/overview/audit-log-overview-wrapper/audit-log-overview-wrapper.component";
import { InspectionCategoryEditWrapperComponent } from "./sections/categories/edit/inspection-category-edit-wrapper/inspection-category-edit-wrapper.component";
import { InspectionCategoriesListWrapperComponent } from "./sections/categories/list/inspection-categories-list-wrapper/inspection-categories-list-wrapper.component";
import { FoldersListWrapperComponent } from "./sections/folders/list/folders-list-wrapper/folders-list-wrapper.component";
import { InspectionDetailsWrapperComponent } from "./sections/inspections/edit/inspection-details/inspection-details-wrapper/inspection-details-wrapper.component";
import { InspectionsListWrapperComponent } from "./sections/inspections/list/inspections-list-wrapper/inspections-list-wrapper.component";
import { InspectionOverviewWrapperComponent } from "./sections/inspections/overview/inspection-overview-wrapper/inspection-overview-wrapper.component";
import { InspectionSignoffEditWrapperComponent } from "./sections/inspections/sign-off/edit/inspection-signoff-edit-wrapper/inspection-signoff-edit-wrapper.component";
import { InspectionSignoffListWrapperComponent } from "./sections/inspections/sign-off/list/inspection-signoff-list-wrapper/inspection-signoff-list-wrapper.component";
import { InspectionInternalNotesComponent } from "./sections/inspections/supplementary/inspection-internal-notes/inspection-internal-notes.component";
import { InspectionIntroductionComponent } from "./sections/inspections/supplementary/inspection-introduction/inspection-introduction.component";
import { InspectionSummaryComponent } from "./sections/inspections/supplementary/inspection-summary/inspection-summary.component";
import { InspectionItemEditWrapperComponent } from "./sections/items/edit/inspection-item-edit-wrapper/inspection-item-edit-wrapper.component";
import { InspectionItemsListWrapperComponent } from "./sections/items/list/inspection-items-list-wrapper/inspection-items-list-wrapper.component";
import { LoginConfirmationComponent } from "./sections/login/login-confirmation/login-confirmation.component";
import { LoginPanelComponent } from "./sections/login/login-panel/login-panel.component";
import { LoginSignupComponent } from "./sections/login/login-signup/login-signup.component";
import { ProfileCompleteComponent } from "./sections/login/profile-complete/profile-complete.component";
import { ReportWrapperComponent } from "./sections/reports/report-wrapper/report-wrapper.component";
import { SectionsWrapperComponent } from "./sections/sections-wrapper/sections-wrapper.component";
import { TestWrapperComponent } from "./sections/tests/test-wrapper/test-wrapper.component";
import { AllWorkspacesWrapperComponent } from "./sections/workspaces/all-workspaces/all-workspaces-wrapper/all-workspaces-wrapper.component";
import { CurrentWorkspaceDeleteComponent } from "./sections/workspaces/current-workspace/current-workspace-delete/current-workspace-delete.component";
import { CurrentWorkspaceWrapperComponent } from "./sections/workspaces/current-workspace/current-workspace-wrapper/current-workspace-wrapper.component";
import { ManageAssigneeEditWrapperComponent } from "./sections/workspaces/current-workspace/manage-assignees/edit/manage-assignee-edit-wrapper/manage-assignee-edit-wrapper.component";
import { ManageAssigneesListWrapperComponent } from "./sections/workspaces/current-workspace/manage-assignees/list/manage-assignees-list-wrapper/manage-assignees-list-wrapper.component";
import { ManageClientEditWrapperComponent } from "./sections/workspaces/current-workspace/manage-clients/edit/manage-client-edit-wrapper/manage-client-edit-wrapper.component";
import { ManageClientsListWrapperComponent } from "./sections/workspaces/current-workspace/manage-clients/list/manage-clients-list-wrapper/manage-clients-list-wrapper.component";
import { ManageMembersEditInviteWrapperComponent } from "./sections/workspaces/current-workspace/manage-members/edit/invites/manage-members-edit-invite-wrapper/manage-members-edit-invite-wrapper.component";
import { ManageMemberEditWrapperComponent } from "./sections/workspaces/current-workspace/manage-members/edit/members/manage-member-edit-wrapper/manage-member-edit-wrapper.component";
import { ManageMembersListWrapperComponent } from "./sections/workspaces/current-workspace/manage-members/list/manage-members-list-wrapper/manage-members-list-wrapper.component";
import { ManageRoleDefaultViewerComponent } from "./sections/workspaces/current-workspace/manage-roles/edit/manage-role-default-viewer/manage-role-default-viewer.component";
import { ManageRoleEditWrapperComponent } from "./sections/workspaces/current-workspace/manage-roles/edit/manage-role-edit-wrapper/manage-role-edit-wrapper.component";
import { ManageRolesListWrapperComponent } from "./sections/workspaces/current-workspace/manage-roles/list/manage-roles-list-wrapper/manage-roles-list-wrapper.component";
import { ManageSiteEditWrapperComponent } from "./sections/workspaces/current-workspace/manage-sites/edit/manage-site-edit-wrapper/manage-site-edit-wrapper.component";
import { ManageSitesListWrapperComponent } from "./sections/workspaces/current-workspace/manage-sites/list/manage-sites-list-wrapper/manage-sites-list-wrapper.component";
import { ManageTemplateCategoryEditWrapperComponent } from "./sections/workspaces/current-workspace/manage-templates/categories/edit/manage-template-category-edit-wrapper/manage-template-category-edit-wrapper.component";
import { ManageTemplateCategoriesListWrapperComponent } from "./sections/workspaces/current-workspace/manage-templates/categories/list/manage-template-categories-list-wrapper/manage-template-categories-list-wrapper.component";
import { ManageTemplateEditWrapperComponent } from "./sections/workspaces/current-workspace/manage-templates/edit/manage-template-edit-wrapper/manage-template-edit-wrapper.component";
import { ManageTemplateItemEditWrapperComponent } from "./sections/workspaces/current-workspace/manage-templates/items/edit/manage-template-item-edit-wrapper/manage-template-item-edit-wrapper.component";
import { ManageTemplateItemsListWrapperComponent } from "./sections/workspaces/current-workspace/manage-templates/items/list/manage-template-items-list-wrapper/manage-template-items-list-wrapper.component";
import { ManageTemplatesListWrapperComponent } from "./sections/workspaces/current-workspace/manage-templates/list/manage-templates-list-wrapper/manage-templates-list-wrapper.component";
import { ManageTemplateOverviewWrapperComponent } from "./sections/workspaces/current-workspace/manage-templates/overview/manage-template-overview-wrapper/manage-template-overview-wrapper.component";
import { ManageThemeEditWrapperComponent } from "./sections/workspaces/current-workspace/manage-themes/edit/manage-theme-edit-wrapper/manage-theme-edit-wrapper.component";
import { ManageThemesListWrapperComponent } from "./sections/workspaces/current-workspace/manage-themes/list/manage-themes-list-wrapper/manage-themes-list-wrapper.component";
import { ManageWorkspaceWrapperComponent } from "./sections/workspaces/current-workspace/manage-workspace/manage-workspace-wrapper/manage-workspace-wrapper.component";

export interface RouteData {
  featureAction: keyof RolePermissions;
}
interface WidthAndRouteData extends RouteData {
  widthStyle: string;
}
export const routes: Routes = [
  {
    canActivate: [SigninGuard],
    path: "signin",
    component: LoginPanelComponent,
  },

  {
    path: "loginconfirmation",
    component: LoginConfirmationComponent,
  },
  {
    path: "signup",
    component: LoginSignupComponent,
  },
  {
    path: "profilecomplete",
    component: ProfileCompleteComponent,
  },

  {
    path: "workspaces",
    canActivate: [AuthGuard, ProfileCompleteGuard],
    component: AllWorkspacesWrapperComponent,
  },

  {
    path: "workspace/:workspaceId",
    canActivate: [AuthGuard, ProfileCompleteGuard],
    component: SectionsWrapperComponent,
    children: [
      {
        path: "folders",
        component: FoldersListWrapperComponent,
      },
      {
        path: "folders/:folderId/inspections",
        component: InspectionsListWrapperComponent,
      },
      {
        path: "folders/:folderId/inspections/:inspectionId/overview",
        component: InspectionOverviewWrapperComponent,
      },
      {
        path: "folders/:folderId/inspections/:inspectionId/details",
        component: InspectionDetailsWrapperComponent,
        canActivate: [LimitationGuard],
        canDeactivate: [PendingChangesGuard],
        data: { featureAction: "inspection_create_update" } as RouteData,
      },
      {
        path: "folders/:folderId/inspections/:inspectionId/categories",
        component: InspectionCategoriesListWrapperComponent,
      },
      {
        path: "folders/:folderId/inspections/:inspectionId/categories/:categoryId/editor",
        component: InspectionCategoryEditWrapperComponent,
        canActivate: [LimitationGuard],
        canDeactivate: [PendingChangesGuard],
        data: { featureAction: "category_create_update" } as RouteData,
      },
      {
        path: "folders/:folderId/inspections/:inspectionId/categories/:categoryId/items",
        component: InspectionItemsListWrapperComponent,
      },

      {
        path: "folders/:folderId/inspections/:inspectionId/categories/:categoryId/items/:itemId/editor",
        component: InspectionItemEditWrapperComponent,
        canActivate: [LimitationGuard],
        canDeactivate: [PendingChangesGuard],
        data: { featureAction: "item_create_update" } as RouteData,
      },
      {
        path: "folders/:folderId/inspections/:inspectionId/categories/:categoryId/items/:itemId",
        component: TestWrapperComponent,
        canDeactivate: [PendingChangesGuard],
        children: [
          {
            path: "action/:actionId",
            component: ActionEditWrapperComponent,
            outlet: "actionEditor",
            canDeactivate: [PendingChangesGuard],
          },
        ],
      },
      {
        path: "folders/:folderId/inspections/:inspectionId/categories/:categoryId/items/:itemId/photo/:photoId",
        component: AnnotationsWrapperComponent,
        data: { route: "fullWidth" },
      },
      {
        path: "folders/:folderId/inspections/:inspectionId/signoff",
        component: InspectionSignoffListWrapperComponent,
        data: { featureAction: "inspection_create_update" } as RouteData,
      },
      {
        path: "folders/:folderId/inspections/:inspectionId/signoff/:signatureId/editor",
        component: InspectionSignoffEditWrapperComponent,
        canActivate: [LimitationGuard],
        canDeactivate: [PendingChangesGuard],
        data: { featureAction: "signature_create_update" } as RouteData,
      },
      {
        path: "folders/:folderId/inspections/:inspectionId/summary",
        component: InspectionSummaryComponent,
        canActivate: [LimitationGuard],
        canDeactivate: [PendingChangesGuard],
        data: { featureAction: "inspection_create_update" } as RouteData,
      },
      {
        path: "folders/:folderId/inspections/:inspectionId/introduction",
        component: InspectionIntroductionComponent,
        canActivate: [LimitationGuard],
        canDeactivate: [PendingChangesGuard],
        data: { featureAction: "inspection_create_update" } as RouteData,
      },
      {
        path: "folders/:folderId/inspections/:inspectionId/internalnotes",
        component: InspectionInternalNotesComponent,
        canActivate: [LimitationGuard],
        canDeactivate: [PendingChangesGuard],
        data: { featureAction: "inspection_create_update" } as RouteData,
      },
      {
        path: "folders/:folderId/inspections/:inspectionId/report",
        component: ReportWrapperComponent,
        data: { route: "fullWidth" },
      },
      {
        path: "manage",
        data: { route: "fullWidth" },
        component: CurrentWorkspaceWrapperComponent,
        children: [
          {
            path: "workspace",
            component: ManageWorkspaceWrapperComponent,
          },
          {
            path: "members",
            component: ManageMembersListWrapperComponent,
          },
          {
            path: "members/:memberId",
            component: ManageMemberEditWrapperComponent,
          },
          {
            path: "members/invites/:inviteId",
            component: ManageMembersEditInviteWrapperComponent,
            canActivate: [LimitationGuard],
            canDeactivate: [PendingChangesGuard],
            data: { featureAction: "invite_create_update" } as RouteData,
          },
          {
            path: "roles",
            component: ManageRolesListWrapperComponent,
          },
          {
            path: "roles/:roleId",
            data: { featureAction: "role_create_update" } as RouteData,
            component: ManageRoleEditWrapperComponent,
          },
          {
            path: "roles/default/:roleId",
            component: ManageRoleDefaultViewerComponent,
          },
          {
            path: "themes",
            component: ManageThemesListWrapperComponent,
          },
          {
            path: "themes/:themeId/editor",
            canActivate: [LimitationGuard],
            canDeactivate: [PendingChangesGuard],
            data: { widthStyle: "fullWidth", featureAction: "theme_create_update" } as WidthAndRouteData,
            component: ManageThemeEditWrapperComponent,
          },
          {
            path: "delete-workspace",
            component: CurrentWorkspaceDeleteComponent,
          },
          {
            path: "assignees",
            component: ManageAssigneesListWrapperComponent,
          },
          {
            path: "assignees/:assigneeId",
            canActivate: [LimitationGuard],
            canDeactivate: [PendingChangesGuard],
            data: { featureAction: "assignee_create_update" } as RouteData,
            component: ManageAssigneeEditWrapperComponent,
          },
          {
            path: "clients",
            component: ManageClientsListWrapperComponent,
          },
          {
            path: "clients/:clientId",
            canActivate: [LimitationGuard],
            canDeactivate: [PendingChangesGuard],
            data: { featureAction: "client_create_update" } as RouteData,
            component: ManageClientEditWrapperComponent,
          },
          {
            path: "sites",
            component: ManageSitesListWrapperComponent,
          },
          {
            path: "sites/:siteId",
            component: ManageSiteEditWrapperComponent,
            canActivate: [LimitationGuard],
            canDeactivate: [PendingChangesGuard],
            data: { featureAction: "site_create_update" } as RouteData,
          },
          {
            path: "templates",
            component: ManageTemplatesListWrapperComponent,
          },
          {
            path: "templates/:templateId/details",
            component: ManageTemplateEditWrapperComponent,
          },
          {
            path: "templates/:templateId/overview",
            component: ManageTemplateOverviewWrapperComponent,
          },
          {
            path: "templates/:templateId/categories",
            component: ManageTemplateCategoriesListWrapperComponent,
          },
          {
            path: "templates/:templateId/categories/:categoryId/editor",
            component: ManageTemplateCategoryEditWrapperComponent,
            canActivate: [LimitationGuard],
            canDeactivate: [PendingChangesGuard],
            data: { featureAction: "template_create_update" } as RouteData,
          },
          {
            path: "templates/:templateId/categories/:categoryId/items",
            component: ManageTemplateItemsListWrapperComponent,
          },
          {
            path: "templates/:templateId/categories/:categoryId/items/:itemId/editor",
            component: ManageTemplateItemEditWrapperComponent,
            canActivate: [LimitationGuard],
            canDeactivate: [PendingChangesGuard],
            data: { featureAction: "template_create_update" } as RouteData,
          },
        ],
      },
      {
        path: "account",
        component: AccountWrapperComponent,
        children: [
          {
            path: "profile",
            component: AccountProfileComponent,
            canDeactivate: [PendingChangesGuard],
          },
          {
            path: "email",
            component: AccountEmailComponent,
            canDeactivate: [PendingChangesGuard],
          },
          {
            path: "subscription",
            component: AccountSubscriptionComponent,
          },
          {
            path: "devices",
            component: AccountConnectedDevicesComponent,
          },
          {
            path: "mydata",
            component: AccountMyDataComponent,
          },
          {
            path: "deleteaccount",
            component: AccountDeleteComponent,
          },
        ],
      },
      {
        path: "auditlogs",
        data: { route: "fullWidth" },
        component: AuditLogsListWrapperComponent,
      },
      {
        path: "auditlogs/:auditLogId",
        data: { route: "fullWidth" },
        component: AuditLogOverviewWrapperComponent,
      },
    ],
  },

  { path: "", redirectTo: "signin", pathMatch: "full" },
];
