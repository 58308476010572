@if (invites && invites.length > 0) {
  <div class="mb-6 w-full rounded-md bg-white px-12 py-8 dark:bg-slate-700">
    <h1 class="text_Title prose dark:prose-invert mb-6 text-4xl font-bold">Invited Workspaces</h1>
    <p class="dark:prose-invert pb-8 font-normal text-[#878787] dark:text-slate-300">You have been invited to join the following workspaces. Tap join to view the Inspections created in those workspaces or create your own Workspace.</p>

    <ul role="list" class="list-ul">
      @for (invite of invites; track $index) {
        <li class="list-row items-center">
          <div class="flex flex-col">
            <p class="list-title">{{ invite.workspace_name }}</p>
            <p class="list-meta">Invited by {{ invite.workspace_invited_by }}</p>
          </div>

          @if (loadingInviteIds().has(invite.id)) {
            <loading-spinner />
          } @else {
            <div class="flex flex-row">
              <button class="btn btn-primary w-[83px]" (click)="acceptOrDeclineInvite({ invite: invite, choice: 'accepted' })">Join</button>
              <button class="btn btn-destructive-light ml-3 w-[83px]" (click)="acceptOrDeclineInvite({ invite: invite, choice: 'declined' })">Decline</button>
            </div>
          }
        </li>
      }
    </ul>
  </div>
}
