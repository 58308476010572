import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { AsyncPipe } from "@angular/common";
import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Operation, RolePermissions, User } from "cip";
import { serverTimestamp, WithFieldValue } from "firebase/firestore";
import { BehaviorSubject, combineLatest, filter, map, Observable, Subscription, switchMap, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { DuplicationService } from "src/app/core/services/duplication/duplication.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { ActionType, LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { NavigationService } from "src/app/core/services/navigation.service";
import { InfoViewStringsService } from "src/app/core/services/strings/info-view-strings.service";
import { OperationStringsService } from "src/app/core/services/strings/operation-strings.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { CategoryEnhanced } from "src/app/models/category/category.model";
import { ItemAnswerOutput } from "src/app/models/item/item-from-list.model";
import { ItemType } from "src/app/models/item/item-type.model";
import { ItemEnhanced } from "src/app/models/item/item.model";
import { OperationEnhanced } from "src/app/models/operation/operation.model";
import { AlertType, InfoViewFilter, InfoViewType, OperationType } from "src/app/models/strings/strings.model";
import { InspectionCategoriesListService } from "src/app/sections/categories/services/inspection-categories-list.service";
import { InspectionCategoryDetailService } from "src/app/sections/categories/services/inspection-category-detail.service";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { DuplicateOverlayComponent } from "src/app/shared/duplicate-overlay/duplicate-overlay.component";
import { EmptyListComponent } from "src/app/shared/empty-list/empty-list.component";
import { EnableDisableOverlayComponent } from "src/app/shared/enable-disable-overlay/enable-disable-overlay.component";
import { InspectionItemNewOverlayComponent } from "../../edit/inspection-item-new-overlay/inspection-item-new-overlay.component";
import { InspectionItemDetailService } from "../../services/inspection-item-detail.service";
import { inspectionItemsListService } from "../../services/inspection-items-list.service";
import { InspectionItemsListCategoriesComponent } from "../inspection-items-list-categories/inspection-items-list-categories.component";
import { InspectionItemsListHeaderComponent } from "../inspection-items-list-header/inspection-items-list-header.component";
import { InspectionItemsListComponent } from "../inspection-items-list/inspection-items-list.component";

@Component({
  selector: "inspection-items-list-wrapper",
  standalone: true,
  imports: [
    AsyncPipe,
    InspectionItemsListHeaderComponent,
    InspectionItemNewOverlayComponent,
    InspectionItemsListComponent,
    InspectionItemsListCategoriesComponent,
    EmptyListComponent,
    DeleteOverlayComponent,
    DuplicateOverlayComponent,
    EnableDisableOverlayComponent,
  ],
  templateUrl: "./inspection-items-list-wrapper.component.html",
  styleUrl: "./inspection-items-list-wrapper.component.scss",
})
export class InspectionItemsListWrapperComponent implements OnInit, OnDestroy {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set folderId(value: string) {
    this._folderId = value;
  }

  get folderId(): string {
    return this._folderId;
  }

  @Input({ required: true }) set inspectionId(value: string) {
    this._inspectionId = value;
  }

  get inspectionId(): string {
    return this._inspectionId;
  }

  @Input({ required: true })
  set categoryId(value: string) {
    if (this._categoryId !== value) {
      this._categoryId = value;
      this.categoryIdSubject.next(value);
    }
  }
  get categoryId(): string {
    return this._categoryId;
  }

  // Services
  private router = inject(Router);
  private authService = inject(AuthService);
  private navigationService = inject(NavigationService);
  private formFormattingService = inject(FormFormattingService);
  private fb = inject(FormBuilder);
  private inspectionItemsListService = inject(inspectionItemsListService);
  private inspectionItemDetailService = inject(InspectionItemDetailService);
  private inspectionCategoriesListService = inject(InspectionCategoriesListService);
  private inspectionCategoryDetailService = inject(InspectionCategoryDetailService);
  private stringsService = inject(StringsService);
  private infoViewStringsService = inject(InfoViewStringsService);
  private limitationManagerService = inject(LimitationManagerService);
  private _snackBar = inject(MatSnackBar);
  private operationStringsService = inject(OperationStringsService);
  public duplicationService = inject(DuplicationService);

  // Properties
  public _workspaceId!: string;
  public _folderId!: string;
  public _inspectionId!: string;
  public _categoryId!: string;
  public allItems: ItemEnhanced[] = [];
  public itemsBulkEditArray$: BehaviorSubject<ItemEnhanced[]> = new BehaviorSubject<ItemEnhanced[]>([]);
  public itemForm: FormGroup;
  public categories$!: Observable<CategoryEnhanced[]>;
  public currentCategory!: CategoryEnhanced;
  public editMode: boolean = false;
  public currentSearchTerm: string = "";
  public deleteTitle: string = "";
  public deleteDescription: string = "";
  public overlay_deleteItem: boolean = false;
  public overlay_newItem: boolean = false;
  public overlay_enableDisableItems: boolean = false;
  public newItemType: ItemType = "test";
  public infoViewItemEditorTest: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.ItemEditorTest);
  public infoViewItemEditorQuestion: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.ItemEditorQuestion);
  public combinedData$!: Observable<{ categories: CategoryEnhanced[]; items: ItemEnhanced[] }>;
  public enableDisableTitle: string = "";
  public enableDisableDescription: string = "";
  private itemToDelete: ItemEnhanced | null = null;
  private user: User;
  private categoryIdSubject = new BehaviorSubject<string>(this.categoryId);

  // Duplicate item
  public itemToBeDuplicated!: ItemEnhanced;
  public duplicationObject!: OperationEnhanced;
  private _duplicationSubscription!: Subscription;
  public duplicationTitle!: string;
  public duplicationDescription!: string;
  public inProgressText!: string;
  public successText!: string;
  public failedText!: string;
  public navigateToText!: string;
  public duplicationType!: string;

  constructor() {
    this.navigationService.breadcrumbsPath = this.router.url;
    this.user = this.authService.currentUser;
    this.itemForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      title: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      answered: null,
      enabled: null,
      answer_type: [null, Validators.required],
      order: null,
      category_id: null,
      mode: [null, Validators.required],
      actions_count: null,
      photos_count: null,
      shows_on_report: null,
      test_answer: null,
      notes: null,
      question_number: null,
      question_string: null,
      question_bool: null,
      question_date: null,
      question_time: null,
      created_by: null,
      created_by_id: null,
    });
  }

  ngOnInit(): void {
    this.getCategoriesList();
    this.combinedData$ = combineLatest([
      this.categories$,
      this.categoryIdSubject.pipe(
        switchMap((categoryId) => this.inspectionItemsListService.getItemsList$(this.workspaceId, this.inspectionId, categoryId)),
        filter((data) => !!data),
        tap((items) => {
          this.allItems = [];
          this.allItems.push(...items);
        })
      ),
    ]).pipe(map(([categories, items]) => ({ categories, items })));
  }

  ngOnDestroy(): void {
    this._duplicationSubscription?.unsubscribe();
  }

  /**
   * Get the list of Categories
   */
  private getCategoriesList(): void {
    try {
      this.categories$ = this.inspectionCategoriesListService.getCategoriesList$(this.workspaceId, this.inspectionId).pipe(
        filter((data) => !!data),
        tap((data) => {
          this.setCurrentCategory(data);
        })
      );
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Set Current Category
   * @param categories
   */
  setCurrentCategory(categories: CategoryEnhanced[]): void {
    const foundCategory = categories.find((obj) => obj.id === this.categoryId);
    if (foundCategory) {
      this.currentCategory = foundCategory;
    } else {
      console.error(`Category with id ${this.categoryId} not found.`);
    }
  }

  /**
   * Set Delete Batch Categories Properties
   */
  public setDeleteBatchItemsProperties(): void {
    if (this.itemsBulkEditArray$.getValue().length > 0) {
      const deleteItems = this.stringsService.alertFilter(AlertType.DeleteItems);
      if (deleteItems) {
        this.deleteTitle = deleteItems.title;
        this.deleteDescription = deleteItems.description;
        this.toggleDeleteItemOverlay();
      }
    }
  }

  /**
   * Update Search Term
   * @param term
   */
  public searchTermChange(term: string) {
    this.currentSearchTerm = term;
  }

  /**
   * Limitation Manager Check
   */
  public async canUserAddItem(type: ItemType): Promise<void> {
    const limitationResult = await this.limitationManagerService.canUserPerformAction("item_create_update");
    if (limitationResult) {
      this.setNewItemMode(type);
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Save Item And Re-Route
   */
  public async saveItemAndReRoute(): Promise<void> {
    try {
      const item = await this.inspectionItemDetailService.setNewItemDoc(this.workspaceId, this.inspectionId, this.categoryId, this.itemForm.value, this.user);
      const route = ["/workspace", this.workspaceId, "folders", this.folderId, "inspections", this.inspectionId, "categories", this._categoryId, "items", item.id];
      this.router.navigate(route);
      this.toggleNewItemOverlay();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Save Item And Add Another
   */
  public async saveItemAndAddAnother(): Promise<void> {
    try {
      const item = await this.inspectionItemDetailService.setNewItemDoc(this.workspaceId, this.inspectionId, this.categoryId, this.itemForm.value, this.user);
      this.toggleSnackBarConfirmation(item.title);
      const type = this.newItemType === "test" ? "test" : "question";
      this.setFormType(type);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Save Item And Add Different Mode
   */
  public async saveItemAndAddDifferentMode(): Promise<void> {
    try {
      const item = await this.inspectionItemDetailService.setNewItemDoc(this.workspaceId, this.inspectionId, this.categoryId, this.itemForm.value, this.user);
      this.toggleSnackBarConfirmation(item.title);
      const type = this.newItemType === "test" ? "question" : "test";
      this.setFormType(type);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Set Form Type
   * @param type
   */
  private setFormType(type: ItemType): void {
    this.itemForm.reset();
    this.itemForm.patchValue({ mode: type });
    this.newItemType = type;
  }

  /**
   * Route To Selected Category
   * @param categoryId
   */
  public routeToSelectedCategory(categoryId: string): void {
    this.router.navigate(["/workspace", this.workspaceId, "folders", this.folderId, "inspections", this.inspectionId, "categories", categoryId, "items"]);
  }

  /**
   * Route To Item Or Test Details Output
   * @param itemId
   */
  public routeToItemOrTestDetailsOutput(itemId: string): void {
    if (this.editMode) {
      this.router.navigate(["/workspace", this.workspaceId, "folders", this.folderId, "inspections", this.inspectionId, "categories", this.categoryId, "items", itemId, "editor"]);
    } else {
      this.router.navigate(["/workspace", this.workspaceId, "folders", this.folderId, "inspections", this.inspectionId, "categories", this.categoryId, "items", itemId]);
    }
  }

  /**
   * Set Delete Single Category Properties
   * @param categoryId
   */
  public setDeleteSingleItemProperties(item: ItemEnhanced): void {
    const deleteItem = this.stringsService.alertFilter(AlertType.DeleteItem);
    if (deleteItem) {
      this.deleteTitle = deleteItem.title;
      this.deleteDescription = deleteItem.description;
    } else {
      console.error("Unknown string type:", AlertType.DeleteItem);
    }
    this.itemToDelete = item;
    this.toggleDeleteItemOverlay();
  }

  /**
   * Delete Single Or Multiple Items
   */
  public deleteSingleOrMultipleItems(): void {
    if (this.itemsBulkEditArray$.getValue().length > 0) {
      this.deleteBatchedItems();
    } else {
      this.deleteSingleItem();
    }
  }

  /**
   * Delete Batched Items
   */
  private async deleteBatchedItems(): Promise<void> {
    try {
      await this.inspectionItemDetailService.deleteBatchedItems(this.workspaceId, this.inspectionId, this.categoryId, this.itemsBulkEditArray$.getValue(), this.allItems, this.user);
      this.toggleDeleteItemOverlay();
      this.checkItemsCountForEditMode();
      this.resetItemsBulkEditArray();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Delete Single Item
   */
  public async deleteSingleItem(): Promise<void> {
    try {
      await this.inspectionItemDetailService.deleteItemDoc(this.workspaceId, this.inspectionId, this.categoryId, this.itemToDelete!, this.allItems, this.user);
      this.toggleDeleteItemOverlay();
      this.editMode = false;
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Check Categories Count For Edit Mode
   */
  private checkItemsCountForEditMode(): void {
    if (this.allItems.length === 0) {
      this.editMode = false;
      this.resetItemsBulkEditArray();
    }
  }

  /**
   * Reset Items Bulk Edit Array
   */
  private resetItemsBulkEditArray(): void {
    this.itemsBulkEditArray$.next([]);
  }

  /**
   * Toggle New Item
   * @param type
   */
  public setNewItemMode(type: ItemType): void {
    if (!this.overlay_newItem) {
      this.setFormType(type);
    }
    this.toggleNewItemOverlay();
  }

  /**
   * Create Batch Edit
   * @param item
   */
  public createBatchEdit(item: ItemEnhanced): void {
    const currentArray = this.itemsBulkEditArray$.getValue();
    if (currentArray.some((e: ItemEnhanced) => e.id === item.id)) {
      this.removeFromBatchArray(item);
    } else {
      this.addToBatchArray(item);
    }
  }

  /**
   * Add To Batch Array
   * @param item
   */
  private addToBatchArray(item: ItemEnhanced): void {
    const updatedArray = [...this.itemsBulkEditArray$.getValue(), item];
    this.itemsBulkEditArray$.next(updatedArray);
  }
  /**
   * Remove From Batch Array
   * @param item
   */
  private removeFromBatchArray(item: ItemEnhanced): void {
    const updatedArray = this.itemsBulkEditArray$.getValue().filter((e) => e.id !== item.id);
    this.itemsBulkEditArray$.next(updatedArray);
  }

  /**
   * Reorder Items
   * @param event
   */
  public async reorderItems(event: CdkDragDrop<string[]>): Promise<void> {
    moveItemInArray(this.allItems, event.previousIndex, event.currentIndex);
    try {
      await this.inspectionItemsListService.reorderItems(this.allItems, this.workspaceId, this.inspectionId, this.user);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Enable/Disable Category
   */
  public async enableDisableCategory(): Promise<void> {
    const enabled = !this.currentCategory.enabled;
    const featureAction: keyof RolePermissions = "category_create_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      try {
        await this.inspectionCategoryDetailService.enableDisableCategory(enabled, this.workspaceId, this.inspectionId, this.categoryId);
      } catch (error) {
        alert(error);
      }
    }
  }

  /**
   * Set Item Answer from List
   * @param itemAnswer
   */
  async setItemAnswer(itemAnswer: ItemAnswerOutput) {
    const featureAction: keyof RolePermissions = "item_create_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    limitationResult ? this.updateItemAnswer(itemAnswer) : (this.limitationManagerService.overlay_limitationManager = true);
  }

  /**
   * Update Item Answer
   * @param itemAnswer
   */
  updateItemAnswer(itemAnswer: ItemAnswerOutput) {
    if (this.currentCategory.enabled) {
      let isItemAnswered = true;

      if (itemAnswer.item.enabled) {
        if (itemAnswer.item.test_answer != null && itemAnswer.item.test_answer === itemAnswer.answer) {
          // Set answered to false because the user has clicked the same answer, so it will be unselected
          isItemAnswered = false;
        }
        // Call the service to update the test answer
        this.inspectionItemDetailService.updateTestAnswerFromList(this.workspaceId, this.inspectionId, this.categoryId, itemAnswer.item, itemAnswer.answer, isItemAnswered, this.user);
      }
    }
  }

  /**
   * Toggle And Set Duplicate item Overlay
   * @param item
   */
  async toggleAndSetDuplicateItemOverlay(item: ItemEnhanced): Promise<void> {
    const featureAction: keyof RolePermissions = "item_create_update";
    const actionType: ActionType = { type: "duplicate" };
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction, actionType);
    if (limitationResult) {
      this.itemToBeDuplicated = item;
      this.duplicationService.overlay_duplicate = true;
      this.duplicationService.overlay_duplicateConfirmation = true;
      const duplicateItem = this.operationStringsService.operationFilter(OperationType.DuplicateItem);
      if (duplicateItem) {
        this.duplicationTitle = duplicateItem.title;
        this.duplicationDescription = duplicateItem.description;
        this.inProgressText = duplicateItem.running;
        this.successText = duplicateItem.success;
        this.failedText = duplicateItem.failure;
        this.navigateToText = "item";
      } else {
        console.error("Unknown string type:", OperationType.Reinspection);
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Duplicate item
   * @param itemTitle
   */
  async duplicateItem(itemTitle: string) {
    this.duplicationService.toggleDuplicationProcess();
    const duplicationInitiation: WithFieldValue<Operation> = {
      type: "duplicate-item",
      created_at: serverTimestamp(),
      status: "pending",
      request: {
        inspection_id: this.inspectionId,
        item_id: this.itemToBeDuplicated.id,
        title: itemTitle,
      },
    };
    const operation = (await this.duplicationService.duplicate(this.workspaceId, duplicationInitiation)) as OperationEnhanced;
    this.subscribeToDuplicationEvent(operation);
  }

  /**
   * Subscribe To Duplication Event
   * @param operation
   */
  subscribeToDuplicationEvent(operation: OperationEnhanced): void {
    this._duplicationSubscription = this.duplicationService.getDuplicationOperation$(this.workspaceId, operation.id).subscribe((result) => {
      if (result) {
        this.duplicationObject = result;
      }
    });
  }

  /**
   * Navigate To New item
   * @returns
   */
  async navigateToNewitem(): Promise<boolean> {
    this.duplicationService.overlay_duplicate = !this.duplicationService.overlay_duplicate;
    return this.router.navigate(["/workspace", this.workspaceId, "folders", this.folderId, "inspections", this.inspectionId, "categories", this.categoryId, "items", this.duplicationObject.response?.new_item_id]);
  }

  /**
   * Toggle Edit Mode
   */
  public async toggleEditMode(): Promise<void> {
    const limitationResult = await this.limitationManagerService.canUserPerformAction("item_create_update");
    if (limitationResult) {
      this.editMode = !this.editMode;
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Toggle Delete Category Overlay
   */
  public toggleDeleteItemOverlay(): void {
    this.overlay_deleteItem = !this.overlay_deleteItem;
  }

  /**
   * Toggle New Item Overlay
   */
  public toggleNewItemOverlay(): void {
    if (this.overlay_newItem) {
      this.itemForm.reset();
    }
    this.overlay_newItem = !this.overlay_newItem;
  }

  /**
   * Toggle Snack Bar Confirmation
   * @param docTitle
   */
  public toggleSnackBarConfirmation(docTitle: string): void {
    this._snackBar.open(`${this.newItemType}: '${docTitle}' added`, "Close", {
      duration: 3000,
    });
  }

  public async enableDisableItems(value: boolean): Promise<void> {
    try {
      await this.inspectionItemsListService.enableDisableItems(this.workspaceId, this.inspectionId, this.itemsBulkEditArray$.value, value, this.user);
    } catch (error) {
      alert(error);
    } finally {
      this.itemsBulkEditArray$.next([]);
      this.toggleEnableDisableItemsOverlay();
      this.toggleEditMode();
    }
  }

  /**
   * Toggle Enable Disable Items Overlay
   */
  public toggleEnableDisableItemsOverlay(): void {
    if (!this.overlay_enableDisableItems) {
      const enableDisableItems = this.stringsService.alertFilter(AlertType.EnableDisableItems);
      if (enableDisableItems) {
        this.enableDisableTitle = enableDisableItems.title;
        this.enableDisableDescription = enableDisableItems.description;
      } else {
        console.error("Unknown string type:", AlertType.DeleteTemplateCategory);
      }
    }
    this.overlay_enableDisableItems = !this.overlay_enableDisableItems;
  }
}
