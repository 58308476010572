<div class="px-4">
  <div class="border-b border-gray-200 py-6">
    <h3 class="-my-3 flow-root">
      <button type="button" class="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500" aria-controls="filter-section-0" aria-expanded="false" (click)="toggleContentOptions()">
        <span class="font-medium text-gray-900">Priority</span>
        <span class="ml-6 flex items-center">
          @if (!toggleContent) {
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
            </svg>
          }
          @if (toggleContent) {
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clip-rule="evenodd" />
            </svg>
          }
        </span>
      </button>
    </h3>
    @if (toggleContent) {
      <div class="pt-6">
        <ul class="space-y-4">
          <!-- <li class="flex items-center">
            <input
              #priorityCheckboxAll
              id="priority-all"
              name="priority-all"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary"
              [(ngModel)]="reportsService.reportSettings.priority_all"
              (change)="toggleAllPriorityCheckboxes()" />
            <label for="priority-all" class="ml-3 cursor-pointer text-sm text-text_Title">Select All</label>
          </li> -->
          <li class="flex items-center">
            <input
              #priorityCheckboxNone
              id="priority-none"
              name="priority-none"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary"
              [(ngModel)]="reportsService.reportSettings.priority_none"
              (change)="onPriorityCheckboxChange(-1, priorityCheckboxNone.checked)" />
            <label for="priority-none" class="ml-3 cursor-pointer text-sm text-text_Title">Actions without Priority</label>
          </li>

          <li class="flex items-center">
            <input
              #priorityCheckboxCritical
              id="priority-critica"
              name="priority-critical"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary"
              [(ngModel)]="reportsService.reportSettings.priority_critical"
              (change)="onPriorityCheckboxChange(100, priorityCheckboxCritical.checked)" />
            <div class="ml-3 h-4 w-4 rounded-full bg-score_0"></div>
            <label for="priority-critica" class="ml-2 cursor-pointer text-sm text-text_Title">Critical</label>
          </li>
          <li class="flex items-center">
            <input
              #priorityCheckboxHigh
              id="priority-high"
              name="priority-high"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary"
              [(ngModel)]="reportsService.reportSettings.priority_high"
              (change)="onPriorityCheckboxChange(75, priorityCheckboxHigh.checked)" />
            <div class="ml-3 h-4 w-4 rounded-full bg-score_25"></div>
            <label for="priority-high" class="ml-2 cursor-pointer text-sm text-text_Title">High</label>
          </li>

          <li class="flex items-center">
            <input
              #priorityCheckboxMedium
              id="priority-medium"
              name="priority-medium"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary"
              [(ngModel)]="reportsService.reportSettings.priority_medium"
              (change)="onPriorityCheckboxChange(50, priorityCheckboxMedium.checked)" />
            <div class="ml-3 h-4 w-4 rounded-full bg-score_50"></div>
            <label for="priority-medium" class="ml-2 cursor-pointer text-sm text-text_Title">Medium</label>
          </li>

          <li class="flex items-center">
            <input
              #priorityCheckboxLow
              id="priority-low"
              name="priority-low"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary"
              [(ngModel)]="reportsService.reportSettings.priority_low"
              (change)="onPriorityCheckboxChange(25, priorityCheckboxLow.checked)" />
            <div class="ml-3 h-4 w-4 rounded-full bg-score_75"></div>
            <label for="priority-low" class="ml-2 cursor-pointer text-sm text-text_Title">Low</label>
          </li>

          <li class="flex items-center">
            <input
              #priorityCheckboxTrivial
              id="priority-trivial"
              name="priority-trivial"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary"
              [(ngModel)]="reportsService.reportSettings.priority_trivial"
              (change)="onPriorityCheckboxChange(0, priorityCheckboxTrivial.checked)" />
            <div class="ml-3 h-4 w-4 rounded-full bg-score_100"></div>
            <label for="priority-trivial" class="ml-2 cursor-pointer text-sm text-text_Title">Trivial</label>
          </li>
        </ul>
      </div>
    }
  </div>
</div>
