import { Injectable } from "@angular/core";
import { Auth, authState, User } from "@angular/fire/auth"; // Use Auth and authState from @angular/fire/auth
import { Billing, Member, RolePermissions } from "cip";
import { BehaviorSubject } from "rxjs";
import { MemberEnhanced } from "src/app/models/member/member.model";
import { ConnectedDevicesService } from "./connected-devices.service";

export interface ActionType {
  type?: "add" | "update" | "delete" | "duplicate";
}

const actionDescriptions: Record<keyof RolePermissions, string> = {
  action_create_update: "Action Create/Update",
  action_delete: "Action Delete",
  assignee_create_update: "Assignee Create/Update",
  assignee_delete: "Assignee Delete",
  category_create_update: "Category Create/Update",
  category_delete: "Category Delete",
  client_create_update: "Client Create/Update",
  client_delete: "Client Delete",
  folder_create_update: "Folder Create/Update",
  folder_delete: "Folder Delete",
  inspection_create_update: "Inspection Create/Update",
  inspection_delete: "Inspection Delete",
  invite_create_update: "Invite Create/Update",
  invite_delete: "Invite Delete",
  item_create_update: "Item Create/Update",
  item_delete: "Item Delete",
  member_update: "Member Create/Update",
  member_delete: "Member Delete",
  photo_create_update: "Photo Create/Update",
  photo_delete: "Photo Delete",
  role_create_update: "Role Create/Update",
  role_delete: "Role Delete",
  signature_create_update: "Signature Create/Update",
  signature_delete: "Signature Delete",
  site_create_update: "Site Create/Update",
  site_delete: "Site Delete",
  template_create_update: "Template Create/Update",
  template_delete: "Template Delete",
  theme_create_update: "Theme Create/Update",
  theme_delete: "Theme Delete",
  workspace_update: "Workspace Update",
};

@Injectable({
  providedIn: "root",
})
export class LimitationManagerService {
  public errorDescription = "";
  public errorTitle = "";
  public overlay_limitationManager = false;
  public billingDoc!: Billing;

  public memberDoc!: BehaviorSubject<MemberEnhanced | null>;

  constructor(
    private auth: Auth, // Replacing AngularFireAuth with Auth
    private connectedDevicesService: ConnectedDevicesService
  ) {
    this.memberDoc = new BehaviorSubject<MemberEnhanced | null>(null);
  }

  // Can User Perform Action
  async canUserPerformAction(action: keyof RolePermissions, actionType?: ActionType): Promise<boolean> {
    const limitations: (() => Promise<boolean>)[] = [() => this.isUserSignedIn(), () => this.isUserSubscribed(actionType as ActionType), () => this.doesUserHaveCorrectPermissions(action), () => this.isUserOverDeviceLimit()];
    for (const limitation of limitations) {
      const result = await limitation();
      if (!result) {
        return false;
      }
    }
    return true;
  }

  // Is User Signed In
  async isUserSignedIn(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      authState(this.auth).subscribe((user: User | null) => {
        if (user) {
          resolve(true);
        } else {
          this.errorTitle = "Authentication Error";
          this.errorDescription = "You are not signed in.";
          resolve(false);
        }
      });
    });
  }

  // Is User Subscribed
  async isUserSubscribed(actionType: ActionType): Promise<boolean> {
    if (this.billingDoc) {
      return this.assessBillingData(this.billingDoc, actionType);
    } else return false;
  }

  // Assess Subscription Data
  assessBillingData(subscription: Billing, actionType?: ActionType): boolean {
    const subscriptionStatus = subscription?.status;

    if (actionType && actionType.type !== undefined) {
      if (actionType.type === "duplicate" && subscriptionStatus === "free") {
        this.errorTitle = "Subscription Error";
        this.errorDescription = "You are not subscribed. If you require access to this function you need to ask a Workspace Admin.";
        return false;
      }
    }
    return true;
  }

  // Does User Have Correct Permissions
  async doesUserHaveCorrectPermissions(action: keyof RolePermissions): Promise<boolean> {
    if (this.memberDoc.value) {
      return this.assessMemberData(this.memberDoc.value, action);
    }
    return false;
  }

  // Assess Member Data
  assessMemberData(member: Member, action: keyof RolePermissions): boolean {
    if (member && member.permissions[action]) {
      return true;
    } else {
      const memberRole = member.role_title;
      const rolePermissions = this.filterRolePermissions(action);
      this.errorTitle = "Permission Denied";
      this.errorDescription = `You are currently assigned the role of "${memberRole}" and do not have the permissions to perform this action. To complete this action, you require permissions for "${rolePermissions}". If you require access to this function you need to ask a Workspace Admin.`;
      return false;
    }
  }

  // Device Limit goes here
  isUserOverDeviceLimit(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const isUnderConnectionLimit = this.connectedDevicesService.isUnderConnectionLimit;
      if (isUnderConnectionLimit) {
        resolve(true);
      } else {
        this.connectedDevicesService.overlay_connectedDevices = true;
        resolve(false);
      }
    });
  }

  filterRolePermissions(action: keyof RolePermissions) {
    return actionDescriptions[action];
  }
}
