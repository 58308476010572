<div id="report-cover-wrapper">
  <div id="report-cover-svg-bg">
    @if (themeSettingsForm.value.styles_cover === "Cover2") {
      <svg width="816" height="1056" viewBox="0 0 816 1056" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          style="mix-blend-mode: multiply"
          d="M0 977.632V718.01H197.368C213.855 718.01 228.848 708.427 235.7 693.434L273.878 610.07C280.782 595.077 295.724 585.494 312.211 585.494H815.918V731.954L775.347 820.54C768.443 835.481 753.501 845.116 737.014 845.116H497.486C480.999 845.116 466.006 854.699 459.153 869.692L420.975 953.056C414.071 968.049 399.13 977.632 382.642 977.632H0Z"
          fill="url(#paint0_linear_56_257)" />
        <path
          style="mix-blend-mode: multiply"
          d="M37.2281 1055.89H0V672.6H446.081C476.788 672.6 497.191 704.337 484.413 732.263L404.09 907.645C397.186 922.638 382.244 932.221 365.757 932.221H126.229C109.742 932.221 94.7488 941.805 87.8963 956.798L49.7181 1040.16C46.8151 1046.47 42.4908 1051.81 37.2281 1055.89Z"
          fill="url(#paint1_linear_56_257)" />
        <path
          d="M815.918 613.939V795.041H657.133C645.644 795.041 635.185 801.739 630.393 812.198L603.756 870.315C598.965 880.774 588.505 887.472 577.016 887.472H224.757C203.323 887.472 189.103 865.318 198.016 845.842L254.021 723.528C258.813 713.069 269.272 706.371 280.762 706.371H447.849C459.339 706.371 469.798 699.673 474.589 689.214L501.226 631.096C506.018 620.637 516.477 613.939 527.967 613.939H815.918Z"
          fill="url(#paint2_linear_56_257)"
          style="mix-blend-mode: multiply" />
        <path
          style="mix-blend-mode: multiply"
          opacity="0.3"
          d="M815.918 341.299V669.48L800.811 702.476C795.831 713.256 785.051 720.206 773.175 720.206H655.027C632.864 720.206 618.198 697.332 627.391 677.193L773.066 359.029C778.046 348.249 788.827 341.299 800.702 341.299H815.918Z"
          fill="url(#paint3_linear_56_257)" />
        <defs>
          <linearGradient id="paint0_linear_56_257" x1="343.788" y1="499.709" x2="423.186" y2="721.01" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" />
            <stop offset="1" [ngStyle]="{ stopColor: themeSettingsForm.value.colours_brand }" />
          </linearGradient>
          <linearGradient id="paint1_linear_56_257" x1="205.74" y1="588.749" x2="312.242" y2="770.494" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" />
            <stop offset="1" [ngStyle]="{ stopColor: themeSettingsForm.value.colours_brand }" />
          </linearGradient>
          <linearGradient id="paint2_linear_56_257" x1="456.806" y1="554.101" x2="508.535" y2="711.318" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" />
            <stop offset="1" [ngStyle]="{ stopColor: themeSettingsForm.value.colours_brand }" />
          </linearGradient>
          <linearGradient id="paint3_linear_56_257" x1="705.215" y1="258.408" x2="817.221" y2="334.165" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" />
            <stop offset="1" [ngStyle]="{ stopColor: themeSettingsForm.value.colours_brand }" />
          </linearGradient>
        </defs>
      </svg>
    }
    @if (themeSettingsForm.value.styles_cover === "Cover1") {
      <svg width="816" height="1056" viewBox="0 0 816 1056" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="816" height="1056" transform="matrix(1 0 0 -1 0 1056)" fill="white" />
        <path opacity="0.5" d="M534 0H816V1056H814.519L534 0Z" [ngStyle]="{ fill: themeSettingsForm.value.colours_brand }" />
        <path d="M816 742L594 1056H816V742Z" [ngStyle]="{ fill: themeSettingsForm.value.colours_brand }" />
        <path opacity="0.48" d="M816 504L564 1056H816V504Z" [ngStyle]="{ fill: themeSettingsForm.value.colours_brand }" />
        <path opacity="0.62" d="M792.52 0L519 1056H816V0H792.52Z" [ngStyle]="{ fill: themeSettingsForm.value.colours_brand }" />
      </svg>
    }
  </div>
  <div id="report-cover-content-wrapper">
    <!-- Top Row -->
    <div id="report-cover-top-row">
      <!-- Workspace -->
      <div class="report-cover-top-row-block">
        @if (themeSettingsForm.value.details_workspace) {
          <div id="report-workspace-logo">
            <img src="../../../../../../../../assets/images/theme-preview/veamlogo.svg" />
          </div>
          <p id="report-workspace-name">Workspace Name</p>
        }
      </div>

      <!-- Top Row Right Data (Design 2 only) -->
      <div class="report-cover-top-row-block">
        <div id="report-inspection-metadata-wrapper-top">
          <p id="report-inspection-reference">Inspection Reference</p>
          <p id="report-site-address">Address</p>
          @if (themeSettingsForm.value.details_user) {
            <div id="report-inspector-wrapper">
              <p id="report-inspected-by-label">Inspected by:</p>
              <p id="report-inspected-by-name">Dr. Jamie Adams</p>
            </div>
          }
        </div>
      </div>
    </div>

    <!-- Main Content -->
    <div id="report-main-content-wrapper">
      <p id="report-inspection-date">{{ themeStylesAndSettingsSerivce.demoDate | dateFormat: themeSettingsForm.value.dates_order : themeSettingsForm.value.dates_style }}</p>
      <p id="report-inspection-title" [ngStyle]="{ color: themeSettingsForm.value.colours_brand }">Inspection Title</p>
      @if (themeSettingsForm.value.details_overall_score) {
        <p id="report-inspection-score">Scored 95%</p>
      }
      @if (themeSettingsForm.value.details_action) {
        <div id="report-inspection-actions-wrapper">
          <p class="report-inspection-action-text report-actions-total">45 Actions</p>
          <p class="report-inspection-action-text report-actions-complete-incomplete">30 Complete</p>
          <p class="report-inspection-action-text report-actions-complete-incomplete">15 Incomplete</p>
        </div>
      }

      <div id="report-inspection-metadata-wrapper-bottom">
        <p id="report-inspection-reference">Inspection Reference</p>
        <p id="report-site-address">Site Address</p>
        @if (themeSettingsForm.value.details_user) {
          <div id="report-inspector-wrapper">
            <p id="report-inspected-by-label">Inspected by:</p>
            <p id="report-inspected-by-name">Dr. Jamie Adams</p>
          </div>
        }
      </div>
    </div>

    <!-- Footer Content -->
    @if (themeSettingsForm.value.details_client) {
      <div id="report-inspection-client-wrapper">
        <div id="report-client-logo">
          <img src="../../../../../../../../assets/images/theme-preview/veamlogo.svg" />
        </div>
        <p id="report-client-name">Client Name</p>
        <p id="report-client-branch">Client Branch</p>
      </div>
    }
  </div>
</div>
