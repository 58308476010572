<div class="overflow-y-auto">
  <nav class="bg-white shadow-sm dark:bg-gray-900">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex h-16 justify-between">
        <div class="hidden sm:flex">
          <div class="flex flex-shrink-0 items-center">
            <img class="hidden h-8 w-auto lg:block" src="./assets/images/cip-logo.png" />
            <div class="ml-3 font-semibold">Checklist Inspector Pro</div>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <div class="flex h-full w-full justify-center dark:bg-gray-800">
    <div class="mt-12 flex w-full max-w-[700px] flex-col pb-12">
      @if (invites$ | async; as invites) {
        <invited-workspaces [invites]="invites" [loadingInviteIds]="isInviteActionProcessing()" (joinWorkspaceOutput)="joinOrDeclineInvite($event)" />
      }
      @if (workspaces$ | async; as workspaces) {
        <your-workspaces [workspaces]="workspaces" (leaveWorkspaceOutput)="setWorkspaceToLeave($event)" />
      }
      <create-workspace
        [workspaceForm]="workspaceForm"
        [uploadFileArray]="uploadFileArray"
        [overlay_newWorkspace]="overlay_newWorkspace"
        [photoPreview]="photoPreview"
        [newWorkspaceId]="newWorkspaceId"
        [overlay_uploadPhotos]="overlay_uploadPhotos"
        (toggleCreateWorkspaceOutput)="toggleCreateWorkspaceOverlay()"
        (detectPhotosOutput)="detectPhotos($event)"
        (saveWorkspaceOutput)="checkIfPhotosAreUploadingOrCreateWorkspace()"
        (removePhotoFromArrayOutput)="removePhotoFromArray($event)"
        (returnedPhotoOutput)="patchFormWithPhotosAndSaveDoc($event)" />

      <div class="mt-8 flex w-full flex-col items-center justify-center">
        <button class="btn btn-destructive flex" type="button" (click)="authService.toggleSignOutOverlay()">Sign Out</button>
        <div class="pt-5 text-sm text-gray-500">v{{ currentAppVersion }}</div>
      </div>
    </div>
  </div>
</div>

@if (overlay_leaveWorkspace && workspaceToLeave) {
  <leave-workspace-wrapper [workspaceToLeave]="workspaceToLeave" [user]="user" (leaveWorkspaceOutput)="leaveWorkspace()" (toggleLeaveWorkspaceOverlayOutput)="toggleLeaveWorkspaceOverlay()" />
}

@if (authService.overlay_signOut) {
  <main-menu-sign-out-overlay />
}

<!-- @if (newAccountForm) {
  <user-name-form [signUpForm]="signUpForm" (createUserDocEmitter)="updateNewUserDoc()" />
} -->
