import { inject, Injectable } from "@angular/core";
import { User } from "cip";
import { increment, orderBy, where } from "firebase/firestore";
import { Observable } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { PhotoEnhanced } from "src/app/models/photo/photo.model";
import { BatchOperation } from "src/app/models/utils/batch";

@Injectable({
  providedIn: "root",
})
export class ItemPhotosService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private lastEventService = inject(LastEventService);

  /**
   * Get Photos List
   * @param workspaceId
   * @param inspectionId
   * @param itemId
   * @returns
   */
  public getPhotosList$(workspaceId: string, inspectionId: string, itemId: string): Observable<PhotoEnhanced[]> {
    const path = this.collectionsService.photosCol(workspaceId, inspectionId);
    const queryConstraints = [where("item_id", "==", itemId), where("is_deleted", "==", false), orderBy("order", "asc")];
    return this.firestoreUtilsService.getCollectionData<PhotoEnhanced>(path, queryConstraints);
  }

  /**
   * Reorder Photos
   * @param photos
   * @param workspaceId
   * @param inspectionId
   * @param user
   */
  async reorderPhotos(photos: PhotoEnhanced[], workspaceId: string, inspectionId: string, user: User) {
    const path = this.collectionsService.photosCol(workspaceId, inspectionId);
    let index = 0;

    const operations: BatchOperation[] = [];

    photos.forEach((photo: PhotoEnhanced) => {
      const doc = `${path}/${photo.id}`;
      const lastEvent = this.lastEventService.lastEvent("changed", user);

      operations.push({
        type: "update",
        documentPath: doc,
        data: {
          order: index,
          last_event: lastEvent,
        },
      });

      index = index + 1;
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Delete Photo Doc
   * @param workspaceId
   * @param inspectionId
   * @param itemId
   * @param photoId
   * @param allPhotos
   * @param user
   */
  public async deletePhotoDoc(workspaceId: string, inspectionId: string, itemId: string, photoId: string, allPhotos: PhotoEnhanced[], user: User): Promise<void> {
    // Photos
    const photosPath = this.collectionsService.photosCol(workspaceId, inspectionId);
    const photoDoc = `${photosPath}/${photoId}`;
    const lastEvent = this.lastEventService.lastEvent("deleted", user);
    const obj = { is_deleted: true, last_event: lastEvent };

    // Test
    const itemPath = this.collectionsService.inspectionItemsCol(workspaceId, inspectionId);
    const itemDoc = `${itemPath}/${itemId}`;

    const operations: BatchOperation[] = [
      {
        type: "set",
        documentPath: photoDoc,
        data: obj,
      },
    ];
    const remainingPhotos = allPhotos.filter((existingPhoto) => existingPhoto.id !== photoId);
    remainingPhotos.forEach((remainingPhoto, index) => {
      const updatePath = `${photosPath}/${remainingPhoto.id}`;
      operations.push({
        type: "update",
        documentPath: updatePath,
        data: { order: index },
      });
    });

    operations.push({
      type: "update",
      documentPath: itemDoc,
      data: { photos_count: increment(-1) },
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }
}
