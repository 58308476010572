<form [formGroup]="siteForm" class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
  <div class="sm:col-span-6">
    <div class="form-input-wrapper">
      <label for="site-address" class="form-input-label">Address</label>
      <textarea type="text" name="site-address" id="site-address" class="form-input" placeholder="Address" formControlName="address"></textarea>
    </div>
  </div>

  <div class="col-span-6">
    <div class="flex flex-col">
      <img class="-ml-2 w-32" src="./../../../../../assets/images/what3words.png" />
      @if (siteForm.value.map_url) {
        <div class="-mt-2 mb-2 flex flex-row dark:text-slate-300">
          <div class="mr-1 text-[#e11f26]">///</div>
          <div class="flex flex-row items-center">
            <button class="cursor-pointer text-brand_Primary hover:text-brand_Primary-hover" title="Copy to clipboard" (click)="copyW3WText(siteForm.value.what_3_words!)" type="button" aria-label="Copy to clipboard">
              {{ siteForm.value.what_3_words }}
            </button>

            <button
              aria-label="Delete What 3 Words Location"
              type="button"
              class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-white bg-brand_Destructive text-white hover:bg-red-500 ml-3"
              (click)="deleteW3WLocation()">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
              </svg>
            </button>
          </div>
          @if (w3wCopied) {
            <div class="text-xs text-text_Subtitle">&nbsp; - Copied!</div>
          }
        </div>
      }
    </div>

    <div class="sm:col-span-6 block">
      @if (!siteForm.value.what_3_words || !new3Words) {
        <p class="list-meta pb-2">We use what3words to find precise locations. Adding a map location will generate a unique combination of three words: a what3words address.</p>
      }


      @if (!siteForm.value.map_url) {
        <button type="button" class="btn btn-primary mb-2" type="button" aria-label="New Site" (click)="showMapComponent()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
          </svg>
          Show Map
        </button>
      }

    </div>

    @if (site && site.map_url && site.what_3_words === siteForm.value.what_3_words) {
      <div class="sm:col-span-6">
        <div class="relative h-[640px] w-[640px]">
          <img src="{{ site.map_url }}" class="h-full w-full" />

          <div class="absolute right-2 top-2 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-white bg-brand_Destructive text-white hover:bg-red-500" (click)="toggleDeleteW3WOverlay()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
            </svg>
          </div>
        </div>
      </div>
    }
  </div>
</form>

@if (overlay_w3w) {
  <delete-overlay [deleteOverlayTitle]="'Remove Location?'" [deleteOverlayDescription]="'Are you sure you want to remove this Location?'" (cancelButtonClicked)="toggleDeleteW3WOverlay()" (deleteButtonClicked)="editWhat3Words()"></delete-overlay>
}
