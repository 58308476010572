import { ApplicationConfig, isDevMode, provideZoneChangeDetection } from "@angular/core";
import { provideRouter, withComponentInputBinding, withRouterConfig } from "@angular/router";

import { getApp, initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { initializeAppCheck, provideAppCheck, ReCaptchaV3Provider } from "@angular/fire/app-check";
import { getAuth, provideAuth } from "@angular/fire/auth";
import { getDatabase, provideDatabase } from "@angular/fire/database";
import { getFirestore, provideFirestore } from "@angular/fire/firestore";
import { getFunctions, provideFunctions } from "@angular/fire/functions";
import { getMessaging, provideMessaging } from "@angular/fire/messaging";
import { getPerformance, providePerformance } from "@angular/fire/performance";
import { getRemoteConfig, provideRemoteConfig } from "@angular/fire/remote-config";
import { getStorage, provideStorage } from "@angular/fire/storage";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { provideServiceWorker } from "@angular/service-worker";

import { provideAnnotatorConfig, ANNOTATOR_FIREBASE_CONFIG_TOKEN } from "@veamstudios/annotator-sdk";
import { environment } from "../environments/environment";
import { routes } from "./app.routes";
import { PendingChangesGuard } from "./core/guards/pending-changes.guard";

if (environment.enableAppCheckDebug && isDevMode()) {
  // @ts-ignore
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

function firebaseAppInitializer() {
  const app = initializeApp(environment.firebase);
  return app;
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withComponentInputBinding(), withRouterConfig({ paramsInheritanceStrategy: "always" })),
    provideFirebaseApp(firebaseAppInitializer),
    provideAppCheck(() => {
      const result = initializeAppCheck(getApp(), {
        provider: new ReCaptchaV3Provider(environment.recaptchaSiteKey),
        isTokenAutoRefreshEnabled: true,
      });
      return result;
    }),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideDatabase(() => getDatabase()),
    provideFunctions(() => getFunctions()),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    provideStorage(() => getStorage()),
    provideRemoteConfig(() => getRemoteConfig()),
    provideAnimationsAsync(),
    provideServiceWorker("ngsw-worker.js", {
      enabled: true,
      registrationStrategy: "registerWhenStable:30000",
    }),
    provideAnimationsAsync(),
    PendingChangesGuard,

    {
      provide: ANNOTATOR_FIREBASE_CONFIG_TOKEN,
      useValue: environment.firebase,
    },
    ...provideAnnotatorConfig(
      {
        provider: 'firebase',
      },
    ),
  ],
};
