<div [nxtSortablejs]="photos" [config]="eventOptions" class="flex flex-row flex-wrap space-x-2 space-y-3">
  @for (photo of photos; track photo.id; let first = $first; let i = $index) {
    <div>
      <div
        class="relative h-40 w-40 cursor-move"
        [ngClass]="{
          'ml-2 mt-3': i === 0,
        }">
        <div class="relative mb-3 mr-3 block h-full w-full rounded-lg bg-gray-100 bg-cover bg-center" [ngStyle]="{ 'background-image': 'url(' + photo.image_thumbnail_url + ')' }"></div>

        <button
          aria-label="Delete Photo"
          type="button"
          class="absolute right-2 top-2 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-white bg-brand_Destructive text-white hover:bg-red-500"
          (click)="deletePhoto(photo.id)">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
          </svg>
        </button>

        <a
          [routerLink]="['/workspace', workspaceId, 'folders', folderId, 'inspections', inspectionId, 'categories', categoryId, 'items', itemId, 'photo', photo.id]"
          class="absolute right-2 top-10 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-white bg-brand_Primary text-white hover:bg-brand_Primary-hover">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.53 16.122a3 3 0 0 0-5.78 1.128 2.25 2.25 0 0 1-2.4 2.245 4.5 4.5 0 0 0 8.4-2.245c0-.399-.078-.78-.22-1.128Zm0 0a15.998 15.998 0 0 0 3.388-1.62m-5.043-.025a15.994 15.994 0 0 1 1.622-3.395m3.42 3.42a15.995 15.995 0 0 0 4.764-4.648l3.876-5.814a1.151 1.151 0 0 0-1.597-1.597L14.146 6.32a15.996 15.996 0 0 0-4.649 4.763m3.42 3.42a6.776 6.776 0 0 0-3.42-3.42" />
          </svg>
        </a>

        <div class="absolute bottom-2 left-2 space-y-2">
          @if (photo.annotation_url) {
            <div class="flex h-8 w-8 items-center justify-center rounded-full bg-slate-700 bg-opacity-35 text-sm text-white">
              <svg viewBox="0 0 80 83" version="1.1" xmlns="http://www.w3.org/2000/svg" class="size-4">
                <g stroke="currentColor" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-1410.000000, -618.000000)" fill="currentColor">
                    <g transform="translate(521.733940, 605.634735)">
                      <g transform="translate(888.342000, 13.265665)">
                        <path
                          d="M32.47066,0 C40.7423427,0 49.193163,2.57187865 56.6096365,7.07184331 L51.9849215,11.7009968 C45.8705442,8.20013268 39.0429309,6.1523 32.32426,6.1523 C15.91796,6.1523 6.10356,17.7246 6.10356,32.6172 C6.10356,48.8769 17.67576,70.70312 46.67966,75.48827 C48.63286,75.73241 49.60936,77.1484281 49.60936,78.56444 C49.60936,80.17577 48.33986,81.83593 45.89846,81.49413 C20.55666,78.61327 0,57.2754 0,32.7637 C0,14.3066 12.49996,0 32.47066,0 Z M71.5583298,20.8969223 C76.5008233,27.872899 79.54106,36.2126699 79.54106,45.3125 C79.54106,58.7891 70.26366,68.16405 56.88476,68.16405 C48.0246962,68.16405 38.352655,64.0637142 30.6450415,57.4178052 L37.416384,54.5591021 C43.3325256,59.2646537 50.3257959,62.3047 56.78706,62.3047 C65.86916,62.3047 73.53516,55.8594 73.53516,45.2637 C73.53516,38.1601617 71.0635249,31.3369728 67.0054853,25.4345493 Z" />
                        <path
                          d="M33.98436,50.1465 L68.26176,15.8691 L62.15816,9.8144 L27.88086,44.043 L24.90236,51.416 C24.56056,52.2949 25.43946,53.2226 26.31836,52.8808 L33.98436,50.1465 Z M71.14256,13.0371 L74.26756,9.9121 C75.73246,8.4473 75.78126,6.1035 74.26756,4.6875 L73.09566,3.6133 C71.77736,2.3926 69.53126,2.3437 68.21286,3.7598 L65.03906,6.9336 L71.14256,13.0371 Z"
                          stroke="currentColor"
                          stroke-width="0.5" />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          }

          <div class="flex h-8 w-8 items-center justify-center rounded-full bg-slate-700 bg-opacity-75 text-sm text-white">{{ i + 1 }}</div>
        </div>
      </div>
    </div>
  }
</div>
