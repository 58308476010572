<header>
  <breadcrumbs [workspaceId]="workspaceId" [folderId]="folderId" [inspectionId]="inspectionId" [categoryId]="categoryId" />

  <div class="page-header-wrapper">
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="page-header">Items</h2>
      </div>
      <div class="mt-4 flex flex-col sm:mt-0 sm:flex-row md:ml-4 md:mt-0">
        @if (items.length > 0 && !editMode) {
          <search-bar [placeholder]="'Search Items'" (searchTermChange)="onSearch($event)" />
        }

        <div class="ml-0 flex flex-shrink-0 flex-col sm:ml-3 sm:flex-row">
          @if (itemsBulkEditArray && itemsBulkEditArray.length > 0) {
            <button type="button" aria-label="button" (click)="bulkDeleteItems()" class="btn btn-secondary mr-3">Bulk Delete</button>
            <button type="button" class="btn btn-secondary mr-3" aria-label="Enable or Disable" (click)="bulkEnableOrDisableItems()">Enable/Disable</button>
          }
          @if (items.length > 0 && currentCategory.enabled) {
            <button class="btn" aria-label="Edit Mode" (click)="toggleEditMode()" [ngClass]="editMode ? 'btn-primary' : 'btn-secondary'">
              {{ editMode ? "Done" : "Edit" }}
            </button>
          }
          @if (currentCategory.enabled && !editMode) {
            <div class="ml-0 sm:ml-3">
              <div mat-button [matMenuTriggerFor]="itemType">
                <button type="New Item" aria-label="button" class="btn btn-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  Item
                </button>
              </div>
            </div>
          }

          <mat-menu #itemType="matMenu" xPosition="before" class="space-y-2">
            <button mat-menu-item (click)="toggleNewItem('test')">
              <div class="w-full border-b border-gray-100 px-4 py-2">
                <p class="text-sm text-text_Title">Add Test</p>
                <p class="text-xs text-text_Subtitle">Score 0% to 100%</p>
              </div>
            </button>
            <button mat-menu-item (click)="toggleNewItem('question')">
              <div class="px-4 py-2">
                <p class="text-sm text-text_Title">Add Question</p>
                <p class="text-xs text-text_Subtitle">Capture extra information</p>
              </div>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</header>
