import { AsyncPipe, NgClass } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { User } from "cip";
import { filter, Observable, tap } from "rxjs";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { ToolTipsStringService } from "src/app/core/services/strings/tooltips.service";
import { IncorrectFileType } from "src/app/models/photo/photo-incorrect-file";
import { PhotoType } from "src/app/models/photo/photo-type";
import { SinglePhotoUpload } from "src/app/models/photo/photo-upload.model";

import { ClientEnhanced } from "src/app/models/client/client.model";
import { ToolTipFilter, ToolTipType } from "src/app/models/strings/strings.model";
import { ClientDetailService } from "src/app/sections/workspaces/current-workspace/manage-clients/services/client-detail.service";
import { ClientsListService } from "src/app/sections/workspaces/current-workspace/manage-clients/services/clients-list.service";
import { TooltipComponent } from "src/app/shared/tooltip/tooltip.component";
import { InspectionDetailsClientSelectorComponent } from "../inspection-details-client-selector/inspection-details-client-selector.component";

@Component({
  selector: "inspection-details-Client-wrapper",
  standalone: true,
  imports: [TooltipComponent, ReactiveFormsModule, NgClass, AsyncPipe, InspectionDetailsClientSelectorComponent],
  templateUrl: "./inspection-details-client-wrapper.component.html",
  styleUrl: "./inspection-details-client-wrapper.component.scss",
})
export class InspectionDetailsClientWrapperComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) user!: User;
  @Input({ required: true }) inspectionForm!: FormGroup;

  // Services
  private toolTipsStringService = inject(ToolTipsStringService);
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private clientsListService = inject(ClientsListService);
  private clientDetailService = inject(ClientDetailService);
  public toolTipInspectionDetailsclient: ToolTipFilter = this.toolTipsStringService.getToolTip(ToolTipType.InspectionDetailsClient);

  // client Options
  public currentlySelectedClient!: ClientEnhanced | null;
  public allOtherClients: Array<ClientEnhanced> = [];

  public clients$!: Observable<ClientEnhanced[]>;
  public clientForm: FormGroup;
  public overlay_clientForm = false;

  public overlay_clientSelector!: boolean;

  // Photos
  public uploadFileArray: SinglePhotoUpload[] = [];
  public incorrectFileTypeArray: IncorrectFileType[] = [];
  public photoPreview: boolean = false;
  public overlay_uploadPhotos = false;
  private tempBlobPhotoURL!: string;
  public newclientId!: string;

  constructor() {
    this.clientForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      name: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      branch: ["", this.formFormattingService.noWhitespaceValidator()],
      image_url: null,
      image_thumbnail_url: null,
      created_by: null,
      created_by_id: null,
    });
    this.currentlySelectedClient = null;
  }

  /**
   * Get clients List
   */
  private async getClientsList(): Promise<void> {
    this.clients$ = this.clientsListService.getClientsList$(this.workspaceId).pipe(
      filter((data) => !!data),
      tap((data) => {
        this.allOtherClients = [];
        this.currentlySelectedClient = null;
        data.forEach((element) => {
          if (element.id === this.inspectionForm.value["client_id"]) {
            this.currentlySelectedClient = element;
          } else {
            this.allOtherClients.push(element);
          }
        });
      })
    );
  }

  /**
   * Save Client
   */
  private async saveClient(): Promise<void> {
    try {
      const client = await this.clientDetailService.saveClientDoc(this.workspaceId, this.newclientId, this.clientForm.value, "added", this.user);
      this.clientSelection(client as ClientEnhanced);
      this.toggleClientFormOverlay();
      this.togglePhotosUploadOverlay();
      this.emptyUploadFileArray();
      this.revokeObjectURL();
      this.clientForm.reset();
      this.newclientId = "";
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Detect Photos
   * @param files
   */
  public async detectPhotos(files: FileList): Promise<void> {
    const file = files[0];
    const fileType = file.type;
    const fileName = file.name;
    const fileExtension = fileType.split("/").pop();
    if (!(fileExtension == "jpg" || fileExtension == "jpeg" || fileExtension == "png")) {
      const incorrectFile: IncorrectFileType = { title: fileName };
      this.incorrectFileTypeArray.push(incorrectFile);
    } else {
      this.uploadFileArray = [];
      this.togglePhotoPreview();
      this.clientForm.markAsDirty();
      this.tempBlobPhotoURL = URL.createObjectURL(file);
      const newId = this.firestoreUtilsService.createFirestoreId();
      this.uploadFileArray.push({
        id: newId,
        photo: this.tempBlobPhotoURL,
      });
    }
  }

  /**
   * client Selection
   * @param client
   */
  public clientSelection(client: ClientEnhanced): void {
    this.inspectionForm.patchValue({
      client_id: client.id,
      client_name: client.name,
      image_thumbnail_url: client.image_thumbnail_url,
    });
    this.currentlySelectedClient = client;
    this.inspectionForm.markAsDirty();
    this.overlay_clientSelector = false;
    this.getClientsList();
  }

  /**
   * Unselect client
   */
  public unselectClient(): void {
    this.inspectionForm.patchValue({
      client_id: null,
      client_name: null,
    });
    this.currentlySelectedClient = null;
    this.inspectionForm.markAsDirty();
    this.getClientsList();
  }

  /**
   * Toggle client Selection
   */
  public async toggleClientSelectionOverlay(): Promise<void> {
    // Toggle the overlay state
    this.overlay_clientSelector = !this.overlay_clientSelector;

    // If the clients$ observable is not yet populated, populate it
    if (!this.clients$) {
      this.getClientsList();
    }

    // If the overlay is now visible (true), create a new client ID
    if (this.overlay_clientSelector) {
      this.newclientId = this.firestoreUtilsService.createFirestoreId();
    }
  }

  /**
   * Remove Photo From Array
   * @param id
   */
  public removePhotoFromArray(id: string): void {
    const indexOfObject = this.uploadFileArray.findIndex((object) => {
      return object.id === id;
    });
    const file = this.uploadFileArray.splice(indexOfObject, 1);
    this.togglePhotoPreview();
    URL.revokeObjectURL(file[0].photo);
    if (this.uploadFileArray.length < 1) {
      this.photoPreview = false;
    }
  }

  /**
   * Patch Form With Photos And Save Doc
   * @param photos
   */
  public async patchFormWithPhotosAndSaveDoc(photos: PhotoType) {
    this.clientForm.patchValue({
      image_url: photos.image_url,
      image_thumbnail_url: photos.image_thumbnail_url,
    });
    try {
      await this.saveClient();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Does A Photo Need Uploading
   */
  public doesAPhotoNeedUploading(): void {
    if (this.uploadFileArray.length > 0) {
      this.togglePhotosUploadOverlay();
    } else {
      this.saveClient();
    }
  }

  /**
   * Toggle Photo Preview
   */
  private togglePhotoPreview(): void {
    this.photoPreview = !this.photoPreview;
  }

  /**
   * Toggle client Form Overlay
   */
  public toggleClientFormOverlay(): void {
    this.overlay_clientForm = !this.overlay_clientForm;
  }

  /**
   * Toggle Photos Upload Overlay
   */
  public togglePhotosUploadOverlay(): void {
    this.overlay_uploadPhotos = !this.overlay_uploadPhotos;
  }

  /**
   * Empty Upload File Array
   */
  private emptyUploadFileArray(): void {
    this.uploadFileArray = [];
  }

  /**
   * Revoke Object URL
   */
  private revokeObjectURL(): void {
    URL.revokeObjectURL(this.tempBlobPhotoURL);
  }
}
