@if (reportsService.reportData.actions.length > 0) {
  <div class="action-wrapper">
    <p class="action-header text-group-2">Actions</p>
    <ul>
      @for (action of reportsService.actionsFiltered; track action.id) {
        @if (action.item_id === itemId) {
          <li>
            <div class="action-li-wrapper">
              <div class="action-content">
                <div class="action-content-left">
                  <p class="action-title text-group-3">{{ action.order + 1 }}. {{ action.title }}</p>
                  @if (action.assignee_name || action.assignee_email) {
                    <div class="action-assignee-wrapper" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">
                      @if (action.assignee_name) {
                        <p class="action-assignee-name text-group-6">{{ action.assignee_name }}</p>
                      }
                      @if (action.assignee_email) {
                        <span>&#8226;</span>
                        <p class="action-assignee-email text-group-6">{{ action.assignee_email }}</p>
                      }
                    </div>
                  }
                  @if (action.due_date) {
                    <p class="action-date text-group-6" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">
                      {{ action.due_date.toDate() | dateFormat: reportsService.themeDetails.value.dates_order : reportsService.themeDetails.value.dates_style }}
                    </p>
                  }
                </div>
                <div class="action-content-right">
                  @if (action.priority || action.priority === 0) {
                    <div class="action-priority text-group-5 action-priority-{{ actionsListService.priorityDict[action.priority].priority }}">
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
                          <circle cx="50" cy="50" r="44" fill="none" stroke-width="8" />
                          <circle cx="50" cy="50" r="16" />
                        </svg>
                        <p>{{ actionsListService.priorityDict[action.priority].priority }}</p>
                      </div>
                    </div>
                  }
                  @if (action.complete) {
                    <ng-container [ngTemplateOutlet]="complete"></ng-container>
                  } @else {
                    <ng-container [ngTemplateOutlet]="incomplete"></ng-container>
                  }
                </div>
              </div>
            </div>
          </li>
        }
      }
    </ul>
  </div>
}

<!-- Complete -->
<ng-template #complete>
  <div class="action-complete text-group-5">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
        <circle cx="50" cy="50" r="48" fill="white"></circle>
        <path d="M32 50 L46 62 L68 36" stroke="#61788D" stroke-width="10"></path>
      </svg>
      <p>Complete</p>
    </div>
  </div>
</ng-template>

<!-- Incomplete -->
<ng-template #incomplete>
  <div class="action-incomplete text-group-5">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
        <circle cx="50" cy="50" r="48" fill="#B9C5CE"></circle>
        <path d="M50 22 V62 M50 80V68" stroke="#F4F7FA" stroke-width="12"></path>
      </svg>
      <p>Incomplete</p>
    </div>
  </div>
</ng-template>
