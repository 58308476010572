<div class="item-wrapper">
  <div class="item-wrapper-inner">
    <div class="item-inner-content">
      <div class="item-header">
        <p class="item-title text-group-2">1.1. Efforts to maintain a skilled and motivated team are evident</p>
      </div>
      <div class="item-pills">
        <p class="item-mode text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">Test</p>
        <div class="item-photos text-group-5">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 84" fill="none">
            <rect x="10" y="9" width="86" height="66" rx="7" fill="white" stroke="#1e293b" stroke-width="7" />
            <path
              [ngStyle]="{ stroke: themeSettingsForm.value.colours_subtitle }"
              d="M69 59L62 52M62 52L45 35C44 33 42 32 40 32V32V32C38 32 36 33 34 35L13 55L10 59M62 52L70 44C71 42 73 41 75 41V41C77 41 79 42 81 44L92 55L95 59"
              stroke="#1e293b"
              stroke-width="7"
              stroke-linecap="round"
              stroke-linejoin="round" />
            <circle cx="62" cy="26" r="7" fill="#1e293b" />
          </svg>

          <p [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">2 Photos</p>
        </div>
        <div class="item-pills-split"></div>
        <div class="item-actions text-group-5">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
            <circle cx="50" cy="50" r="44" stroke="#1e293b" stroke-width="8" [ngStyle]="{ stroke: themeSettingsForm.value.colours_subtitle }"></circle>
            <path d="M32 50 L46 62 L68 36" stroke="#1e293b" stroke-width="10" [ngStyle]="{ stroke: themeSettingsForm.value.colours_subtitle }"></path>
          </svg>
          <p [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">2 Actions</p>
        </div>

        <!-- Demo Content -->
        <div class="item-result text-group-5 item-result-amber">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none">
            <rect x="0.921875" y="0.491211" width="15.2524" height="15.2524" rx="7.6262" fill="white" />
            <path d="M8.54688 3.54199V8.11771L11.0889 10.1514" stroke="#F1AB42" stroke-width="1.52524" stroke-linecap="square" stroke-linejoin="round" />
          </svg>
          <p>In Progress</p>
        </div>
        <!-- Actual Conditions -->
        <!-- <ng-template [ngIf]="action.test_answer === 'pass'" #pass></ng-template>
      <ng-template [ngIf]="action.test_answer === 'yes'" #yes></ng-template>
      <ng-template [ngIf]="action.test_answer === 'high'" #high></ng-template>
      <ng-template [ngIf]="action.test_answer === 'green'" #green></ng-template>
      <ng-template [ngIf]="action.test_answer === 'good'" #good></ng-template>
      <ng-template [ngIf]="action.test_answer === 'progress-100'" #completed></ng-template>

      <ng-template [ngIf]="action.test_answer === 'amber'" #amber></ng-template>
      <ng-template [ngIf]="action.test_answer === 'fair'" #fair></ng-template>
      <ng-template [ngIf]="action.test_answer === 'progress-50'" #inProgress></ng-template>

      <ng-template [ngIf]="action.test_answer === 'fail'" #fail></ng-template>
      <ng-template [ngIf]="action.test_answer === 'no'" #no></ng-template>
      <ng-template [ngIf]="action.test_answer === 'low'" #low></ng-template>
      <ng-template [ngIf]="action.test_answer === 'red'" #red></ng-template>
      <ng-template [ngIf]="action.test_answer === 'poor'" #poor></ng-template>
      <ng-template [ngIf]="action.test_answer === 'progress-0'" #notStarted></ng-template>

      --></div>
      <p class="item-notes text-group-4" [ngStyle]="{ color: themeSettingsForm.value.colours_content }">
        Efforts to maintain a skilled and motivated team are evident in our ongoing training and development initiatives. Ensuring that each employee is equipped with the necessary skills and knowledge not only elevates our customer service but also
        enhances our compliance with HR policies and industry regulations.
      </p>
    </div>
    <div class="item-photos-wrapper text-group-5">
      <manage-theme-edit-preview-item-photos [themeSettingsForm]="themeSettingsForm" />
    </div>
  </div>
  <manage-theme-edit-preview-action [themeSettingsForm]="themeSettingsForm" />
</div>

<div class="item-wrapper">
  <div class="item-wrapper-inner">
    <div class="item-inner-content">
      <div class="item-header">
        <p class="item-title text-group-2">1.2. Efforts to maintain a skilled and motivated team are evident</p>
      </div>
      <div class="item-pills">
        <p class="item-mode text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">Question</p>
      </div>
      <p class="item-notes text-group-4" [ngStyle]="{ color: themeSettingsForm.value.colours_content }">
        Efforts to maintain a skilled and motivated team are evident in our ongoing training and development initiatives. Ensuring that each employee is equipped with the necessary skills and knowledge not only elevates our customer service but also
        enhances our compliance with HR policies and industry regulations.
      </p>
      <div class="item-answer-wrapper">
        <p class="item-answer-title text-group-4" [ngStyle]="{ color: themeSettingsForm.value.colours_brand }">Answer</p>
        <p class="item-answer text-group-4" [ngStyle]="{ color: themeSettingsForm.value.colours_content }">Efforts to maintain a skilled and motivated team are evident in our ongoing training and development initiatives.</p>
      </div>
    </div>
  </div>
</div>
