import { Injectable, OnDestroy } from "@angular/core";
import { Auth, deleteUser, signInWithEmailAndPassword, user, User } from "@angular/fire/auth";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { User as Usermodel } from "cip";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { AlertType } from "../../models/strings/strings.model";
import { appStorage } from "../util/app-storage";
import { assertDefined, assertNonNull } from "../util/assert";
import { StringsService } from "./strings/strings.service";
import { UsersService } from "./users.service";
@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  public get currentUser(): Usermodel {
    const { value } = this._currentUser$;
    assertNonNull(value, `Expected 'currentUser' to be non-null but received null.`);
    return value;
  }

  public get currentUser$(): Observable<Usermodel | null> {
    return this._currentUser$.asObservable();
  }

  public _currentUser$ = new BehaviorSubject<Usermodel | null>(null);

  private _subs: Subscription[] = [];

  // Overlay for sign out
  public overlay_signOut = false;

  // Delete Account toggles
  public overlay_deleteAccount = false;
  public deleteConfirmation!: string;
  public deleteOverlayTitle!: string;
  public deleteOverlayDescription!: string;

  constructor(
    private auth: Auth,
    private _userService: UsersService,
    private _router: Router,
    private stringsService: StringsService
  ) {
    const sub = user(this.auth)
      .pipe(
        filter((authState): authState is User => !!authState), // Type casting authState to User
        switchMap((firebaseUser: User) => {
          return this._userService.getUserById(firebaseUser.uid); // Now TypeScript knows firebaseUser is of type User
        })
      )
      .subscribe({
        next: (user: Usermodel | undefined) => {
          // Explicitly define the type of 'user'
          assertDefined(user, `Expected 'user' to be defined but received undefined.`);
          this._currentUser$.next(user);
        },
        error: () => {
          this._router.navigateByUrl("/signin");
        },
      });

    this._subs.push(sub);
  }

  /**
   * Sign In
   * @param email
   * @param password
   * Sign in with email and password
   * If successful, navigate to workspaces/folders
   * If unsuccessful, log error to console
   */
  public signIn(email: string, password: string) {
    signInWithEmailAndPassword(this.auth, email, password)
      .then(() => {
        this._router.navigate(["workspaces/folders"]);
      })
      .catch((err) => {
        console.log("Something is wrong:", err.message);
      });
  }

  /**
   * Toggle sign out overlay
   */
  toggleSignOutOverlay() {
    this.overlay_signOut = !this.overlay_signOut;
  }

  /**
   * Toggle delete account overlay
   */
  toggleDeleteAccountOverlay() {
    console.log("toggleDeleteAccountOverlay");
    this.overlay_deleteAccount = !this.overlay_deleteAccount;
    const deleteAccount = this.stringsService.alertFilter(AlertType.DeleteAccount);
    if (deleteAccount) {
      this.deleteOverlayTitle = deleteAccount.title;
      this.deleteOverlayDescription = deleteAccount.description;
    } else {
      console.error("Unknown string type:", AlertType.DeleteAccount);
    }
  }

  /**
   * Delete account
   * Successful deletion will sign out user
   * Unsuccessful deletion will alert user
   */
  deleteAccount() {
    const currentUser = this.auth.currentUser;

    if (currentUser) {
      deleteUser(currentUser)
        .then(() => {
          // Optionally sign out the user here
          // this.signOut();
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  async createUserDoc(signUpForm: FormGroup) {
    const currentUser = this.auth.currentUser;
    if (currentUser) {
      const user: Usermodel = {
        user_id: currentUser.uid,
        name_first: signUpForm.value.name_first,
        name_last: signUpForm.value.name_last,
        email: currentUser.email!,
      };
      try {
        await this._userService.saveUser(user);
      } catch (error) {
        alert(error);
      }
    }
  }

  /**
   * Unsubscribe from all subscriptions
   */
  ngOnDestroy(): void {
    this._subs.forEach((sub) => {
      sub?.unsubscribe();
    });
  }

  setEmailForSignInToLocalStorage(email: string): void {
    appStorage.setEmailForSignin(email);
  }

  setNewAccountToLocalStorage(): void {
    appStorage.setCipIsNewAccount(true);
  }

  getEmailFromLocalStorage() {
    return appStorage.getEmailForSignin();
  }

  isNewAccountAccordingToStorage() {
    return appStorage.getCipIsNewAccount() === true;
  }

  removeEmailFromLocalStorage(): void {
    appStorage.removeEmailForSignin();
  }

  removeNewAccountFromLocalStorage(): void {
    appStorage.removeCipIsNewAccount();
  }
}
