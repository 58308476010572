@if (action$ | async; as action) {
  <div class="relative z-30" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500/75 transition-opacity"></div>

    <div class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <div class="flex h-full w-full flex-1" (click)="closePanel()"></div>
        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
          <div class="pointer-events-auto w-screen max-w-2xl">
            <div class="flex h-full flex-col overflow-y-scroll overscroll-contain bg-gray-50">
              <div>
                <div class="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8">
                  <div class="flex items-center justify-between">
                    <h2 class="page-header">{{ actionId === "new" ? "Add " : "Edit " }} Action</h2>
                    <div class="ml-3 flex h-7 items-center">
                      <button (click)="closePanel()" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brand_Primary focus:ring-offset-2">
                        <span class="sr-only">Close panel</span>
                        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="relative flex-1 pb-8">
                <div class="grid grid-cols-1 gap-y-8 overscroll-contain px-6">
                  <div class="flex flex-row items-center justify-end px-8">
                    <form-state [pristine]="actionForm.pristine"></form-state>
                    <button type="button" (click)="saveActionDoc()" class="btn" [disabled]="actionForm.pristine || actionForm.invalid" [ngClass]="actionForm.pristine || actionForm.invalid ? 'btn-disabled' : 'btn-primary'">Save</button>
                  </div>
                  <action-edit-details [actionForm]="actionForm" />
                  <action-edit-priority [actionForm]="actionForm" />
                  <action-edit-assignee-wrapper [actionForm]="actionForm" [workspaceId]="workspaceId" [user]="user" />
                  <action-edit-complete [actionForm]="actionForm" />
                  <div class="flex flex-row items-center justify-between px-6 pb-8 sm:px-8">
                    @if (actionId !== "new") {
                      <button type="button" class="btn btn-destructive mr-3" (click)="setDeleteOverlayProperties()">Delete Action</button>
                    }
                    <div class="flex flex-1 flex-row items-center justify-end">
                      <form-state [pristine]="actionForm.pristine"></form-state>
                      <button type="button" (click)="saveActionDoc()" class="btn" [disabled]="actionForm.pristine || actionForm.invalid" [ngClass]="actionForm.pristine || actionForm.invalid ? 'btn-disabled' : 'btn-primary'">Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
} @else {
  <loading-spinner />
}

@if (overlay_deleteAction()) {
  <delete-overlay [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (deleteButtonClicked)="deleteAction()" (cancelButtonClicked)="toggleDeleteOverlay(false)" />
}
