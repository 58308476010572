import { inject, Injectable } from '@angular/core';

import {
  getDownloadURL,
  ref,
  Storage,
  uploadBytes,
} from '@angular/fire/storage';

import { type Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CollectionsService } from 'src/app/core/services/collections/collections.service';
import { FirestoreUtilsService } from 'src/app/core/services/firestore/firestore-utils.service';
import { PhotoEnhanced } from 'src/app/models/photo/photo.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PhotoDetailService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private fbStorage = inject(Storage);

  private readonly metadataPNG = {
    contentType: 'image/png',
    cacheControl: 'public, max-age=31536000',
    customMetadata: {
      platform: 'Web: v' + environment.version,
    },
  };

  private annotationSuffix = '_annotation.png';

  /**
   * Get Photo Document
   * @param path
   */
  public getPhotoDoc$(path: string): Observable<PhotoEnhanced | null> {
    return this.firestoreUtilsService.getDocumentData<PhotoEnhanced>(path).pipe(
      catchError((error: Error) => {
        const err = new Error(error.message);

        return throwError(() => err);
      }),
    );
  }

  public async saveAnnotationsAsPng(
    path: string,
    photoId: string,
    blob: Blob,
  ) {
    const storageRef = ref(this.fbStorage, `${path}/${photoId}${this.annotationSuffix}`);

    return uploadBytes(storageRef, blob, this.metadataPNG)
      .then(() =>
        getDownloadURL(storageRef)
          .then((url) =>
            this.firestoreUtilsService.setPartialDocumentData<PhotoEnhanced>(
              path,
              {
                annotation_url: url,
              },
            ),
          )
          .catch(console.error),
      )
      .catch(console.error);
  }

  public getPhotoPath(
    workspaceId: string,
    inspectionId: string,
    photoId: string,
  ): string {
    return `${this.collectionsService.photosCol(workspaceId, inspectionId)}/${photoId}`;
  }
}
