import { inject, Injectable } from "@angular/core";
import { User } from "cip";
import { orderBy, where } from "firebase/firestore";
import { Observable } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { ItemEnhanced } from "src/app/models/item/item.model";
import { BatchOperation } from "src/app/models/utils/batch";

@Injectable({
  providedIn: "root",
})
export class inspectionItemsListService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private lastEventService = inject(LastEventService);

  // Properties
  // For the inspection overview navigation (3 of 4 answered)
  public totalTests = 0;
  public totalTestsWithAnswers = 0;
  public percentageOfAnsweredTests = 0;

  private callCount = 0;

  /**
   * Get Items List
   * @param workspaceId
   * @param inspectionId
   * @param categoryId
   */
  public getItemsList$(workspaceId: string, inspectionId: string, categoryId: string): Observable<ItemEnhanced[]> {
    const path = this.collectionsService.inspectionItemsCol(workspaceId, inspectionId);
    const queryConstraints = [where("category_id", "==", categoryId), where("is_deleted", "==", false), orderBy("order", "asc")];
    return this.firestoreUtilsService.getCollectionData<ItemEnhanced>(path, queryConstraints);
  }

  /**
   * Get Inspection Tests
   * @param workspaceId
   * @param inspectionId
   */
  public getInspectionTests$(workspaceId: string, inspectionId: string): Observable<ItemEnhanced[]> {
    this.callCount++;
    const path = this.collectionsService.inspectionItemsCol(workspaceId, inspectionId);
    const queryConstraints = [where("enabled", "==", true), where("is_deleted", "==", false)];
    return this.firestoreUtilsService.getCollectionData<ItemEnhanced>(path, queryConstraints);
  }

  /**
   * Reorder Items
   * @param items
   * @param workspaceId
   * @param inspectionId
   * @param user
   */
  async reorderItems(items: ItemEnhanced[], workspaceId: string, inspectionId: string, user: User) {
    const path = this.collectionsService.inspectionItemsCol(workspaceId, inspectionId);
    let index = 0;

    const operations: BatchOperation[] = [];

    items.forEach((item: ItemEnhanced) => {
      const doc = `${path}/${item.id}`;
      const lastEvent = this.lastEventService.lastEvent("changed", user);

      operations.push({
        type: "update",
        documentPath: doc,
        data: {
          order: index,
          last_event: lastEvent,
        },
      });

      index = index + 1;
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Enable Disable Items
   * @param workspaceId
   * @param inspectionId
   * @param items
   * @param enabled
   * @param user
   */
  async enableDisableItems(workspaceId: string, inspectionId: string, items: ItemEnhanced[], enabled: boolean, user: User): Promise<void> {
    const path = this.collectionsService.inspectionItemsCol(workspaceId, inspectionId);
    const operations: BatchOperation[] = [];

    items.forEach((item: ItemEnhanced) => {
      const doc = `${path}/${item.id}`;
      const lastEvent = this.lastEventService.lastEvent("changed", user);
      const obj = { enabled: enabled, last_event: lastEvent };

      operations.push({
        type: "update",
        documentPath: doc,
        data: obj,
      });
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }
}
