@if (themeSettingsForm.value.charts_included) {
  <div class="charts-cover-wrapper">
    <div class="header">
      <div class="title text-group-1" [ngStyle]="{ color: themeSettingsForm.value.colours_brand }">Charts</div>
      <div class="divider" [ngStyle]="{ 'background-color': themeSettingsForm.value.colours_brand }"></div>
    </div>
    <div class="charts-content-wrapper">
      <!-- 1. Scores -->
      @if (themeSettingsForm.value.charts_score) {
        <div class="charts-scores-wrapper">
          <div class="charts-title text-group-2">Scores</div>
          <div class="chart-total scores text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">3 Tests</div>
          <charts-overall [items]="chartsTests" [widthHeight]="40"></charts-overall>
        </div>
      }
      <!-- 2. Actions -->
      @if (themeSettingsForm.value.charts_action) {
        <div class="charts-actions-wrapper">
          <div class="charts-title text-group-2">Actions</div>
          <div class="chart-total actions text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">2 Actions</div>
          <charts-actions [actions]="chartsActions"></charts-actions>
        </div>
      }

      <!-- 3. Assignees-->
      @if (themeSettingsForm.value.charts_assignee) {
        <div class="charts-assignees-wrapper">
          <div class="charts-title text-group-2">Assignees</div>
          <div class="chart-total assignees text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">2 Assignees</div>
          <charts-assignees [chartsAssignees]="chartsAssignees" [assigneeHighestActionCount]="5"></charts-assignees>
        </div>
      }
    </div>
  </div>
}
