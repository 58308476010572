import { Clipboard, ClipboardModule } from "@angular/cdk/clipboard";
import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Subscription } from "rxjs";
import { SiteEnhanced } from "src/app/models/site/site.model";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { environment } from "src/environments/environment";
import { SiteDetailService } from "../../services/site-detail.service";

export interface MapDetail {
  coordinates: {
    lng: number;
    lat: number;
  };
  country: string;
  language: string;
  map_url: string;
  nearestPlace: string;
  words: string;
}

interface CustomEventWithValueDetail extends CustomEvent {
  detail: MapDetail;
}

@Component({
  selector: "manage-site-edit-location",
  standalone: true,
  imports: [ReactiveFormsModule, ClipboardModule, DeleteOverlayComponent],
  templateUrl: "./manage-site-edit-location.component.html",
  styleUrl: "./manage-site-edit-location.component.scss",
})
export class ManageSiteEditLocationComponent implements OnInit, OnDestroy {
  @Input({ required: true }) siteForm!: FormGroup;
  @Input({ required: true }) inspectionOrSite!: "inspection" | "site";
  @Input() site!: SiteEnhanced;

  private clipboard = inject(Clipboard);
  private siteDetailService = inject(SiteDetailService);

  // What 3 Words
  public new3Words!: string;
  public w3w_api_key = environment.w3w_api_key;
  public map_api_key = environment.map_key;
  public w3wCopied = false;

  // W3W delete Overlay
  public overlay_w3w = false;

  private mapInstance: HTMLElement | null = null;
  private autosuggestInstance: HTMLElement | null = null;
  private eventListeners: { element: HTMLElement; type: string; handler: (event: Event) => void }[] = [];
  private subscription = new Subscription();

  async ngOnInit(): Promise<void> {
    this.subscription = this.siteDetailService.viewSiteMap$.subscribe(async (isVisible) => {
      if (isVisible) {
        await this.initializeMap();
        this.cleanupEventListeners();
        this.setupEventListeners();
      } else {
        this.cleanupEventListeners();
      }
    });
  }

  ngOnDestroy(): void {
    this.cleanupEventListeners();
    this.subscription.unsubscribe();
  }

  /**
   * Initialize the map component
   */
  private async initializeMap(): Promise<void> {
    try {
      // Wait for custom elements to be ready
      await customElements.whenDefined("what3words-map");
      await customElements.whenDefined("what3words-autosuggest");

      // Get references to the elements
      this.mapInstance = document.getElementById("w3w-map-element");
      this.autosuggestInstance = document.getElementById("w3w-autosuggest-element");

      // Initialize with proper API keys
      if (this.mapInstance) {
        this.mapInstance.setAttribute("api_key", this.w3w_api_key);
        this.mapInstance.setAttribute("map_api_key", this.map_api_key);
      }
    } catch (error) {
      console.error("Error initializing What3Words map:", error);
    }
  }

  /**
   * Setup event listeners
   */
  private setupEventListeners(): void {
    setTimeout(() => {
      this.cleanupEventListeners();

      // Refresh element references
      this.mapInstance = document.getElementById("w3w-map-element");
      this.autosuggestInstance = document.getElementById("w3w-autosuggest-element");

      if (this.autosuggestInstance) {
        const selectHandler = (value: Event) => {
          const details: MapDetail = (value as CustomEventWithValueDetail).detail;
          this.patchForm(details);
        };

        this.autosuggestInstance.addEventListener("select", selectHandler);
        this.eventListeners.push({
          element: this.autosuggestInstance,
          type: "select",
          handler: selectHandler,
        });
      }

      if (this.mapInstance) {
        const squareHandler = (value: Event) => {
          const details: MapDetail = (value as CustomEventWithValueDetail).detail;
          this.patchForm(details);
        };

        this.mapInstance.addEventListener("selected_square", squareHandler);
        this.eventListeners.push({
          element: this.mapInstance,
          type: "selected_square",
          handler: squareHandler,
        });
      }
    }, 100); // Small delay to ensure elements are ready
  }

  /**
   * Cleanup event listeners
   */
  private cleanupEventListeners(): void {
    this.eventListeners.forEach(({ element, type, handler }) => {
      element?.removeEventListener(type, handler);
    });
    this.eventListeners = [];
  }

  /**
   * Show Map Component
   */
  public showMapComponent(): void {
    this.siteDetailService.viewSiteMapSubject.next(!this.siteDetailService.viewSiteMapSubject.getValue());
  }

  /**
   * Delete W3W Location
   */
  public deleteW3WLocation(): void {
    this.siteForm.markAsDirty();
    this.siteForm.patchValue({
      what_3_words: null,
      longitude: null,
      latitude: null,
      map_url: null,
    });

  }

  /**
   * Patch Form
   * @param details
   */
  private patchForm(details: MapDetail): void {
    if (details) {
      const googleApiRef = "https://maps.googleapis.com/maps/api/staticmap?";
      const coordinates = details.coordinates.lat + "," + details.coordinates.lng;
      const zoom = "&zoom=15";
      const size = "&size=640x640";
      const marker = "&markers=color:red|label:S|";
      const key = "&key=" + `${environment["map_key"]}`;
      const staticImageUrl = googleApiRef + size + zoom + marker + coordinates + key;

      this.new3Words = details.words;

      this.siteForm.markAsDirty();

      this.siteForm.patchValue({
        what_3_words: details.words,
        longitude: details.coordinates.lng,
        latitude: details.coordinates.lat,
        map_url: staticImageUrl,
      });
    }
  }

  /**
   * Copy W3W Text
   * @param value
   */
  public copyW3WText(value: string): void {
    this.clipboard.copy(value);
    this.w3wCopied = true;
    setTimeout(() => {
      this.w3wCopied = false;
    }, 3000);
  }

  /**
   * Edit What3Words
   */
  public editWhat3Words(): void {
    this.site.map_url = "";
    this.siteForm.markAsDirty();
    this.siteForm.patchValue({
      what_3_words: null,
      longitude: null,
      latitude: null,
      map_url: null,
    });
    this.toggleDeleteW3WOverlay();
  }

  /**
   * Toggle Delete W3W Overlay
   */
  public toggleDeleteW3WOverlay(): void {
    this.overlay_w3w = !this.overlay_w3w;
  }
}
