import { NgClass } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { CategoryEnhanced } from "src/app/models/category/category.model";
import { ItemType } from "src/app/models/item/item-type.model";
import { ItemEnhanced } from "src/app/models/item/item.model";
import { BreadcrumbsComponent } from "src/app/shared/breadcrumbs/breadcrumbs.component";
import { SearchBarComponent } from "src/app/shared/search-bar/search-bar.component";
@Component({
  selector: "inspection-items-list-header",
  standalone: true,
  imports: [BreadcrumbsComponent, NgClass, MatMenuModule, MatButtonModule, SearchBarComponent],
  templateUrl: "./inspection-items-list-header.component.html",
  styleUrl: "./inspection-items-list-header.component.scss",
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionItemsListHeaderComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) folderId!: string;
  @Input({ required: true }) inspectionId!: string;
  @Input({ required: true }) categoryId!: string;
  @Input({ required: true }) items: ItemEnhanced[] = [];
  @Input({ required: true }) editMode!: boolean;
  @Input({ required: true }) currentCategory!: CategoryEnhanced;
  @Input() itemsBulkEditArray: ItemEnhanced[] | null = [];
  @Output() searchTermChange = new EventEmitter<string>();
  @Output() toggleNewItemOutput = new EventEmitter<ItemType>();
  @Output() toggleEditModeOutput = new EventEmitter<void>();
  @Output() bulkDeleteItemsOutput = new EventEmitter<void>();
  @Output() bulkEnableOrDisableItemsOutput = new EventEmitter<ItemEnhanced>();

  /**
   * On Search
   * @param searchTerm
   */
  public onSearch(searchTerm: string) {
    this.searchTermChange.emit(searchTerm);
  }

  /**
   * Toggle Disabled Items
   */
  public bulkEnableOrDisableItems(): void {
    this.bulkEnableOrDisableItemsOutput.emit();
  }

  /**
   * Bulk Delete Items
   */
  public bulkDeleteItems(): void {
    this.bulkDeleteItemsOutput.emit();
  }

  /**
   * Toggle Edit Mode
   */
  public toggleEditMode(): void {
    this.toggleEditModeOutput.emit();
  }

  /**
   * Toggle New Item
   */
  public toggleNewItem(type: ItemType): void {
    this.toggleNewItemOutput.emit(type);
  }
}
