@if (combinedData$ | async; as combinedData) {
  <inspection-items-list-header
    [workspaceId]="workspaceId"
    [folderId]="folderId"
    [inspectionId]="inspectionId"
    [categoryId]="categoryId"
    [items]="combinedData.items"
    [itemsBulkEditArray]="itemsBulkEditArray$ | async"
    [currentCategory]="currentCategory"
    [editMode]="editMode"
    (searchTermChange)="searchTermChange($event)"
    (toggleNewItemOutput)="canUserAddItem($event)"
    (toggleEditModeOutput)="toggleEditMode()"
    (bulkDeleteItemsOutput)="setDeleteBatchItemsProperties()"
    (bulkEnableOrDisableItemsOutput)="toggleEnableDisableItemsOverlay()" />

  @if (!editMode) {
    <inspection-items-list-categories [categories]="combinedData.categories" [categoryId]="categoryId" (categorySelectOutput)="routeToSelectedCategory($event)" />
  }

  @if (!editMode) {
    <div class="mx-auto flex w-full max-w-7xl justify-end px-4 pb-10 sm:px-6 lg:px-8">
      <button type="Enable Category" aria-label="button" class="btn-secondary-small-default cursor-pointer bg-white" (click)="enableDisableCategory()">
        {{ currentCategory.enabled ? "Disable Category" : "Enable Category" }}
      </button>
    </div>
  }
  @if (combinedData.items.length > 0) {
    <inspection-items-list
      [items]="combinedData.items"
      [searchTerm]="currentSearchTerm"
      [itemsBulkEditArray]="itemsBulkEditArray$ | async"
      [editMode]="editMode"
      [currentCategory]="currentCategory"
      (reorderItemsOutput)="reorderItems($event)"
      (createBatchEditOutput)="createBatchEdit($event)"
      (deleteSingleItemOutput)="setDeleteSingleItemProperties($event)"
      (setItemAnswerOutput)="setItemAnswer($event)"
      (toggleDuplicateOverlayOutput)="toggleAndSetDuplicateItemOverlay($event)"
      (routeToItemOrTestDetailsOutput)="routeToItemOrTestDetailsOutput($event)" />
  } @else {
    <empty-list [list]="'item'" />
  }
}

<!-- New -->
@if (overlay_newItem) {
  <inspection-item-new-overlay
    [type]="newItemType"
    [itemForm]="itemForm"
    [infoViewItemEditorTest]="infoViewItemEditorTest"
    [infoViewItemEditorQuestion]="infoViewItemEditorQuestion"
    (toggleNewItemOutput)="toggleNewItemOverlay()"
    (saveItemAndRerouteOutput)="saveItemAndReRoute()"
    (saveItemAndAddAnotherOutput)="saveItemAndAddAnother()"
    (saveItemAndAddDifferentModeOutput)="saveItemAndAddDifferentMode()" />
}

<!-- Delete -->
@if (overlay_deleteItem) {
  <delete-overlay [deleteOverlayTitle]="deleteTitle" [deleteOverlayDescription]="deleteDescription" (cancelButtonClicked)="toggleDeleteItemOverlay()" (deleteButtonClicked)="deleteSingleOrMultipleItems()" />
}

<!-- Duplicate -->
@if (duplicationService.overlay_duplicate) {
  <duplicate-overlay
    [duplicationObject]="duplicationObject"
    [originalDocumentTitle]="itemToBeDuplicated.title + ' ' + '(Copy)'"
    [duplicationTitle]="duplicationTitle"
    [duplicationDescription]="duplicationDescription"
    [inProgressText]="inProgressText"
    [successText]="successText"
    [failedText]="failedText"
    [navigateToText]="navigateToText"
    (duplicateEmitter)="duplicateItem($event)"
    (navigateToNewDocEmitter)="navigateToNewitem()" />
}

@if (overlay_enableDisableItems) {
  <enable-disable-overlay [overlayTitle]="enableDisableTitle" [overlayDescription]="enableDisableDescription" (enabled)="enableDisableItems($event)" (cancelButtonClicked)="toggleEnableDisableItemsOverlay()" />
}
