@if (photoDoc$ | async; as photoDoc) {
  <div class="pt-10 px-2 pb-6">
    <breadcrumbs [workspaceId]="_workspaceId" [folderId]="_folderId" [inspectionId]="_inspectionId" [categoryId]="_categoryId" [itemId]="_itemId" [photoId]="_photoId" />
  </div>
  @if (photoDoc.image_url) {
    <lib-annotator size="full" bodySize="full" [photoPath]="photoDoc.image_url" [annotationsPath]="annotationsPath" (save)="handleSave($event)" />

    @if ((showSpinner$ | async) === true) {
      <div class="flex h-full items-center justify-center absolute z-50 inset-0 before:bg-white before:opacity-45 before:content-[''] before:absolute before:z-40 before:inset-0">
        <loading-spinner />
      </div>
    }
  }
}
