import { AsyncPipe, JsonPipe, NgClass, NgStyle } from "@angular/common";
import { Component, HostListener, inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { RolePermissions, User } from "cip";
import { filter, Observable, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { IncorrectFileType } from "src/app/models/photo/photo-incorrect-file";
import { PhotoType } from "src/app/models/photo/photo-type";
import { SinglePhotoUpload } from "src/app/models/photo/photo-upload.model";
import { SiteEnhanced } from "src/app/models/site/site.model";
import { AlertType } from "src/app/models/strings/strings.model";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { DropzoneDirective } from "src/app/shared/directives/dropzone.directive";
import { FormStateComponent } from "src/app/shared/form-state/form-state.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { PhotoDropzoneComponent } from "src/app/shared/photo-dropzone/photo-dropzone.component";
import { PhotoPreviewComponent } from "src/app/shared/photo-preview/photo-preview.component";
import { PhotoUploadSingleComponent } from "src/app/shared/photo-upload-single/photo-upload-single.component";
import { SiteDetailService } from "../../services/site-detail.service";
import { ManageSiteEditDetailsComponent } from "../manage-site-edit-details/manage-site-edit-details.component";
import { ManageSiteEditHeaderComponent } from "../manage-site-edit-header/manage-site-edit-header.component";
import { ManageSiteEditLocationComponent } from "../manage-site-edit-location/manage-site-edit-location.component";

@Component({
  selector: "manage-site-edit-wrapper",
  standalone: true,
  imports: [
    ManageSiteEditHeaderComponent,
    ManageSiteEditDetailsComponent,
    ManageSiteEditLocationComponent,
    AsyncPipe,
    FormStateComponent,
    LoadingSpinnerComponent,
    NgClass,
    NgStyle,
    JsonPipe,
    DropzoneDirective,
    PhotoPreviewComponent,
    PhotoUploadSingleComponent,
    PhotoDropzoneComponent,
    DeleteOverlayComponent,
  ],
  templateUrl: "./manage-site-edit-wrapper.component.html",
  styleUrl: "./manage-site-edit-wrapper.component.scss",
})
export class ManageSiteEditWrapperComponent implements OnInit {
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return this.isFormDirty();
  }

  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true })
  set siteId(value: string) {
    this._isNewSite = value === "new";
    this._siteId = value === "new" ? this.firestoreUtilsService.createFirestoreId() : value;
  }

  get siteId(): string {
    return this._siteId;
  }

  // Add a getter for the flag
  get isNewSite(): boolean {
    return this._isNewSite;
  }
  // Services
  private siteDetailService = inject(SiteDetailService);
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);
  private router = inject(Router);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private stringsService = inject(StringsService);
  private authService = inject(AuthService);
  private limitationManagerService = inject(LimitationManagerService);

  // Properties
  private _workspaceId!: string;
  private _siteId!: string;
  private _isNewSite: boolean = false;
  public site$!: Observable<SiteEnhanced>;
  public siteForm!: FormGroup;
  public uploadFileArray: SinglePhotoUpload[] = [];
  public incorrectFileTypeArray: IncorrectFileType[] = [];
  public photoPreview: boolean = false;
  public overlay_uploadPhotos = false;
  private tempBlobPhotoURL!: string;
  public overlay_deleteSitePhoto: boolean = false;
  public overlay_deleteSite: boolean = false;
  public deleteOverlayTitle: string = "";
  public deleteOverlayDescription: string = "";
  private user: User;

  constructor() {
    this.user = this.authService.currentUser;
    this.siteForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      title: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      ref: ["", this.formFormattingService.noWhitespaceValidator()],
      address: ["", this.formFormattingService.noWhitespaceValidator()],
      map_url: null,
      image_url: null,
      image_thumbnail_url: null,
      what_3_words: null,
      longitude: null,
      latitude: null,
      created_by: null,
      created_by_id: null,
    });
  }

  ngOnInit(): void {
    this.getSiteDoc();
  }

  /**
   * Get the site document
   */
  private async getSiteDoc(): Promise<void> {
    try {
      this.site$ = this.siteDetailService.getSiteDoc$(this.workspaceId, this.siteId).pipe(
        filter((data) => !!data),
        tap((data) => {
          if (this.siteForm.pristine && this.siteForm.untouched) {
            this.siteForm.patchValue(data);
          }
        })
      );
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Save Site
   */
  public async saveSite(): Promise<void> {
    try {
      const writeEventType = this.isNewSite ? "added" : "changed";
      await this.siteDetailService.setSiteDoc(this.workspaceId, this.siteId, this.siteForm.value, writeEventType, this.user);
      this.siteForm.markAsPristine();
      this.router.navigate(["/workspace", this.workspaceId, "manage", "sites"]);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Delete Site
   */
  public async deleteSite(): Promise<void> {
    try {
      await this.siteDetailService.deleteSiteDoc(this.workspaceId, this.siteId, this.user);
      this.router.navigate(["/workspace", this.workspaceId, "manage", "sites"]);
      this.toggleDeleteSiteOverlay();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Can User Delete Site
   */
  public async canUserDeleteSite(): Promise<void> {
    const featureAction: keyof RolePermissions = "site_delete";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      const deleteSite = this.stringsService.alertFilter(AlertType.DeleteSite);
      if (deleteSite) {
        this.deleteOverlayTitle = deleteSite.title;
        this.deleteOverlayDescription = deleteSite.description;
      } else {
        console.error("Unknown string type:", AlertType.DeleteClient);
      }
      this.toggleDeleteSiteOverlay();
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Does A Photo Need Uploading
   */
  public doesAPhotoNeedUploading(): void {
    if (this.uploadFileArray.length > 0) {
      this.togglePhotosUploadOverlay();
    } else {
      this.saveSite();
    }
  }

  /**
   * Detect Photos
   * @param files
   */
  public async detectPhotos(files: FileList): Promise<void> {
    const file = files[0];
    const fileType = file.type;
    const fileName = file.name;
    const fileExtension = fileType.split("/").pop();
    if (!(fileExtension == "jpg" || fileExtension == "jpeg" || fileExtension == "png")) {
      const incorrectFile: IncorrectFileType = { title: fileName };
      this.incorrectFileTypeArray.push(incorrectFile);
    } else {
      this.uploadFileArray = [];
      this.togglePhotoPreview();
      this.siteForm.markAsDirty();
      this.tempBlobPhotoURL = URL.createObjectURL(file);
      const newId = this.firestoreUtilsService.createFirestoreId();
      this.uploadFileArray.push({
        id: newId,
        photo: this.tempBlobPhotoURL,
      });
    }
  }

  /**
   * Remove Image From Array
   * @param id
   */
  public removePhotoFromArray(id: string): void {
    const indexOfObject = this.uploadFileArray.findIndex((object) => {
      return object.id === id;
    });
    const file = this.uploadFileArray.splice(indexOfObject, 1);
    this.togglePhotoPreview();
    URL.revokeObjectURL(file[0].photo);
    if (this.uploadFileArray.length < 1) {
      this.photoPreview = false;
    }
  }

  /**
   * Patch Form With Photos And Save Doc
   * @param photos
   */
  public async patchFormWithPhotosAndSaveDoc(photos: PhotoType) {
    this.siteForm.patchValue({
      image_url: photos.image_url,
      image_thumbnail_url: photos.image_thumbnail_url,
    });
    try {
      await this.saveSite();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Toggle Delete Site Image Overlay
   */
  public toggleDeleteSitePhotoOverlay(): void {
    this.overlay_deleteSitePhoto = !this.overlay_deleteSitePhoto;
    const deleteSitePhoto = this.stringsService.alertFilter(AlertType.DeleteSitePhoto);
    if (deleteSitePhoto) {
      this.deleteOverlayTitle = deleteSitePhoto.title;
      this.deleteOverlayDescription = deleteSitePhoto.description;
    } else {
      console.error("Unknown string type:", AlertType.DeleteSitePhoto);
    }
  }

  /**
   * Remove Site Image Properties From Form
   */
  public removeSiteImagePropertiesFromForm(): void {
    this.siteForm.patchValue({
      image_url: null,
      image_thumbnail_url: null,
    });
    this.siteForm.markAsDirty();
    this.toggleDeleteSitePhotoOverlay();
  }

  /**
   * Toggle Photos Upload Overlay
   */
  public togglePhotosUploadOverlay(): void {
    this.overlay_uploadPhotos = !this.overlay_uploadPhotos;
  }

  /**
   * Toggle Photo Preview
   */
  private togglePhotoPreview(): void {
    this.photoPreview = !this.photoPreview;
  }

  /**
   * Toggle Delete Site Overlay
   */
  public toggleDeleteSiteOverlay(): void {
    this.overlay_deleteSite = !this.overlay_deleteSite;
  }

  /**
   * Is Form Dirty
   * @returns true or false
   * If the form is dirty return false
   * If the form isn't dirty return true
   */
  private isFormDirty(): boolean {
    return this.siteForm.dirty ? false : true;
  }
}
