@if (site$ | async; as site) {
  <manage-site-edit-header [workspaceId]="workspaceId" />
  <div class="grid gap-y-8 px-6 py-5">
    <div class="flex flex-row items-center justify-end px-6 sm:px-8">
      <form-state [pristine]="siteForm.pristine === true || !!siteForm.controls['title'].errors" />
      <button type="button" (click)="doesAPhotoNeedUploading()" class="btn" [disabled]="siteForm.pristine || siteForm.invalid" [ngClass]="siteForm.pristine || siteForm.invalid ? 'btn-disabled' : 'btn-primary'">Save</button>
    </div>

    <div class="content-card-wrapper">
      <div class="content-card-header">
        <h3>Details</h3>
      </div>
      <div class="px-6 py-5">
        <div class="content-grid gap-x-4 gap-y-6">
          <!-- Details -->

          <manage-site-edit-details [siteForm]="siteForm" />

          <div>
            <div class="form-input-label mb-2 px-3">Site Photo</div>
            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-6">
                @if (siteForm.value.image_thumbnail_url) {
                  <div class="relative flex h-40 w-40 rounded-md bg-gray-600 bg-cover bg-center" [ngStyle]="{ 'background-image': 'url(' + siteForm.value.image_thumbnail_url + ')' }">
                    <div class="absolute right-2 top-2 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-white bg-brand_Destructive text-white hover:bg-red-500" (click)="toggleDeleteSitePhotoOverlay()">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
                      </svg>
                    </div>
                  </div>
                }

                <!-- Image Dropzone -->
                @if (!siteForm.value.image_thumbnail_url && uploadFileArray.length < 1) {
                  <photo-dropzone [uploadSourceComponent]="'site'" (detectPhotosOutput)="detectPhotos($event)" />
                }

                <!-- Image Preview -->
                @if (photoPreview && uploadFileArray.length > 0) {
                  <photo-preview [photosToPreview]="uploadFileArray" [uploadLimit]="1" [uploadSourceComponent]="'site'" (removePhotoFromArrayOutput)="removePhotoFromArray($event)" />
                }

                <!-- Image Uploading-->
                @if (overlay_uploadPhotos) {
                  @for (photo of uploadFileArray; track photo.id) {
                    <photo-upload-single
                      [file]="photo.photo"
                      [path]="'workspaces/' + workspaceId + '/sites' + siteId"
                      [fileType]="'jpeg'"
                      [largeMaxWidth]="2000"
                      [largeMaxHeight]="2000"
                      [thumbnailMaxWidth]="300"
                      [thumbnailMaxHeight]="300"
                      [uploadSourceComponent]="'site'"
                      (photoDataOutput)="patchFormWithPhotosAndSaveDoc($event)" />
                  }
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-card-wrapper">
      <div class="content-card-header">
        <h3>Location</h3>
      </div>
      <div class="px-6 py-5">
        <div class="content-grid gap-x-4 gap-y-6">
          <manage-site-edit-location [siteForm]="siteForm" [inspectionOrSite]="'site'" [site]="site" />
        </div>
      </div>
    </div>

    <div class="flex flex-row items-center justify-between px-6 sm:px-8">
      <button type="button" class="btn btn-destructive mr-3" (click)="canUserDeleteSite()">Delete Site</button>
      <div class="flex flex-1 flex-row items-center justify-end">
        <form-state [pristine]="siteForm.pristine === true || !!siteForm.controls['title'].errors" />
        <button type="button" (click)="doesAPhotoNeedUploading()" class="btn" [disabled]="siteForm.pristine || siteForm.invalid" [ngClass]="siteForm.pristine || siteForm.invalid ? 'btn-disabled' : 'btn-primary'">Save</button>
      </div>
    </div>
  </div>
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}

@if (overlay_deleteSitePhoto) {
  <delete-overlay [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (cancelButtonClicked)="toggleDeleteSitePhotoOverlay()" (deleteButtonClicked)="removeSiteImagePropertiesFromForm()" />
}

@if (overlay_deleteSite) {
  <delete-overlay [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (cancelButtonClicked)="toggleDeleteSiteOverlay()" (deleteButtonClicked)="deleteSite()" />
}
