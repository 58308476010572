@for (item of reportsService.reportData.items; track item.id) {
  @if (item.category_id === category.id) {
    <div class="item-wrapper">
      <div class="item-wrapper-inner">
        <div class="item-inner-content">
          <div class="item-header-wrapper">
            <div class="item-header">
              <p class="item-title text-group-2">{{ category.order + 1 }}.{{ item.order + 1 }} - {{ item.title }}</p>
            </div>
            @if (item.mode === "test") {
              <div class="item-pills">
                <p class="item-mode text-group-5" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">{{ item.mode }}</p>
                <div class="item-photos text-group-5">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 11" fill="none">
                    <rect x="1.28564" y="1.21431" width="11.1429" height="8.57143" rx="0.857143" fill="white" stroke-width="0.857143" [ngStyle]="{ stroke: reportsService.themeDetails.value.colours_subtitle }" />
                    <path
                      d="M8.99993 7.64293L8.14279 6.78578M8.14279 6.78578L5.8744 4.5174C5.68037 4.32336 5.4172 4.21436 5.14279 4.21436V4.21436V4.21436C4.86838 4.21436 4.60521 4.32336 4.41117 4.5174L1.71422 7.21436L1.28564 7.64293M8.14279 6.78578L9.12546 5.80312C9.31949 5.60908 9.58266 5.50007 9.85707 5.50007V5.50007C10.1315 5.50007 10.3947 5.60908 10.5887 5.80312L11.9999 7.21436L12.4285 7.64293"
                      [ngStyle]="{ stroke: reportsService.themeDetails.value.colours_subtitle }"
                      stroke-width="0.857143"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                    <circle cx="8.14279" cy="3.35722" r="0.857143" [ngStyle]="{ fill: reportsService.themeDetails.value.colours_subtitle }" />
                  </svg>
                  <p [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">{{ item.photos_count }} {{ item.photos_count === 1 ? "Photo" : "Photos" }}</p>
                </div>
                <div class="item-pills-split"></div>
                <div class="item-actions text-group-5">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" fill="none">
                    <circle cx="6.78564" cy="6.14279" r="5.5" fill="white" [ngStyle]="{ stroke: reportsService.themeDetails.value.colours_subtitle }" />
                    <path d="M4.6713 6.17216L6.15617 7.65718L9.04294 4.77061" [ngStyle]="{ stroke: reportsService.themeDetails.value.colours_subtitle }" stroke-width="0.857143" />
                  </svg>
                  <p [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">{{ item.actions_count }} {{ item.actions_count === 1 ? "Action" : "Actions" }}</p>
                </div>

                @switch (item.test_answer) {
                  @case ("pass") {
                    <ng-container [ngTemplateOutlet]="pass"></ng-container>
                  }
                  @case ("yes") {
                    <ng-container [ngTemplateOutlet]="yes"></ng-container>
                  }
                  @case ("high") {
                    <ng-container [ngTemplateOutlet]="high"></ng-container>
                  }
                  @case ("green") {
                    <ng-container [ngTemplateOutlet]="green"></ng-container>
                  }
                  @case ("good") {
                    <ng-container [ngTemplateOutlet]="good"></ng-container>
                  }
                  @case ("progress-100") {
                    <ng-container [ngTemplateOutlet]="completed"></ng-container>
                  }
                  @case ("amber") {
                    <ng-container [ngTemplateOutlet]="amber"></ng-container>
                  }
                  @case ("fair") {
                    <ng-container [ngTemplateOutlet]="fair"></ng-container>
                  }
                  @case ("progress-50") {
                    <ng-container [ngTemplateOutlet]="inProgress"></ng-container>
                  }
                  @case ("fail") {
                    <ng-container [ngTemplateOutlet]="fail"></ng-container>
                  }
                  @case ("no") {
                    <ng-container [ngTemplateOutlet]="no"></ng-container>
                  }
                  @case ("low") {
                    <ng-container [ngTemplateOutlet]="low"></ng-container>
                  }
                  @case ("red") {
                    <ng-container [ngTemplateOutlet]="red"></ng-container>
                  }
                  @case ("poor") {
                    <ng-container [ngTemplateOutlet]="poor"></ng-container>
                  }
                  @case ("progress-0") {
                    <ng-container [ngTemplateOutlet]="notStarted"></ng-container>
                  }
                }
              </div>
            }
          </div>

          @if (item.notes) {
            <p class="item-notes text-group-4" [ngStyle]="{ color: reportsService.themeDetails.value.colours_content }">{{ item.notes }}</p>
          }

          @if (item.mode === "question") {
            <div class="item-answer-wrapper">
              <p class="item-answer-title text-group-4" [ngStyle]="{ color: reportsService.themeDetails.value.colours_brand }">Answer</p>
              <div class="item-answer text-group-4" [ngStyle]="{ color: reportsService.themeDetails.value.colours_content }">
                @switch (item.answer_type) {
                  @case ("question-bool") {
                    <p>{{ item.question_bool }}</p>
                  }
                  @case ("question-date") {
                    <div>
                      @if (item.question_date) {
                        <p>{{ item.question_date.toDate() | date: "dd MMM yyyy" }}</p>
                      }
                    </div>
                  }
                  @case ("question-number") {
                    <p>{{ item.question_number }}</p>
                  }
                  @case ("question-string") {
                    <p>{{ item.question_string }}</p>
                  }
                  @case ("question-time") {
                    <div>
                      @if (item.question_time) {
                        <p>{{ item.question_time.toDate() | date: "h:mm:ss a" }}</p>
                      }
                    </div>
                  }
                }
              </div>
            </div>
          }
        </div>

        @if (item.photos_count > 0) {
          <div class="item-photos-wrapper text-group-5">
            <report-content-photos [itemId]="item.id" />
          </div>
        }
      </div>

      @if (item.actions_count > 0) {
        <report-content-actions [itemId]="item.id" />
      }
    </div>
  }

  <!-- Pass -->
  <ng-template #pass>
    <div class="item-result text-group-5 item-result-green">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
        <circle cx="50" cy="50" r="44" fill="white"></circle>
        <path d="M32 50 L46 62 L68 36" stroke="#61788D" stroke-width="10"></path>
      </svg>
      <p>Pass</p>
    </div>
  </ng-template>

  <!-- Yes -->
  <ng-template #yes>
    <div class="item-result text-group-5 item-result-green">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
        <circle cx="50" cy="50" r="44" fill="white"></circle>
        <path d="M32 50 L46 62 L68 36" stroke="#61788D" stroke-width="10"></path>
      </svg>
      <p>Yes</p>
    </div>
  </ng-template>

  <!-- High -->
  <ng-template #high>
    <div class="item-result text-group-5 item-result-green">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
        <circle cx="50" cy="50" r="44" fill="white"></circle>
        <path d="M50 72V28M28 50L50 30L72 50" stroke="#439457" stroke-width="10" stroke-linejoin="round"></path>
      </svg>
      <p>High</p>
    </div>
  </ng-template>

  <!-- Green -->
  <ng-template #green>
    <div class="item-result text-group-5 item-result-green">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
        <circle cx="50" cy="50" r="44" fill="white" />
        <circle cx="50" cy="50" r="16" fill="#439457" />
      </svg>
      <p>Green</p>
    </div>
  </ng-template>

  <!-- Good -->
  <ng-template #good>
    <div class="item-result text-group-5 item-result-green">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
        <circle cx="50" cy="50" r="44" fill="white"></circle>
        <path d="M50 72V28M28 50L50 30L72 50" stroke="#439457" stroke-width="10" stroke-linejoin="round"></path>
      </svg>
      <p>Good</p>
    </div>
  </ng-template>

  <!-- Completed -->
  <ng-template #completed>
    <div class="item-result text-group-5 item-result-green">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
        <circle cx="50" cy="50" r="44" fill="white"></circle>
        <path d="M32 50 L46 62 L68 36" stroke="#61788D" stroke-width="10"></path>
      </svg>
      <p>Completed</p>
    </div>
  </ng-template>

  <!-- Amber -->
  <ng-template #amber>
    <div class="item-result text-group-5 item-result-amber">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
        <circle cx="50" cy="50" r="44" fill="white" />
        <circle cx="50" cy="50" r="16" fill="#F1AB42" />
      </svg>
      <p>Amber</p>
    </div>
  </ng-template>
  <!-- Fair -->
  <ng-template #fair>
    <div class="item-result text-group-5 item-result-amber">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
        <circle cx="50" cy="50" r="44" fill="white"></circle>
        <path d="M50 72V28M28 50L50 30L72 50" stroke="#F1AB42" stroke-width="10" stroke-linejoin="round" transform="rotate(90 50 50)"></path>
      </svg>
      <p>Fair</p>
    </div>
  </ng-template>

  <!-- In Progress -->
  <ng-template #inProgress>
    <div class="item-result text-group-5 item-result-amber">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
        <circle cx="50" cy="50" r="44" fill="white"></circle>
        <path d="M50 25V50 52L65 62" stroke="#F1AB42" stroke-width="10" stroke-linecap="square" stroke-linejoin="round" />
      </svg>
      <p>In Progress</p>
    </div>
  </ng-template>

  <!-- Fail -->
  <ng-template #fail>
    <div class="item-result text-group-5 item-result-red">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
        <circle cx="50" cy="50" r="44" fill="white"></circle>
        <path d="M32 32 L68 68" stroke="#F23D3D" stroke-width="10" stroke-linejoin="round" />
        <path d="M32 68 L68 32" stroke="#F23D3D" stroke-width="10" stroke-linejoin="round" />
      </svg>
      <p>Fail</p>
    </div>
  </ng-template>

  <!-- No -->
  <ng-template #no>
    <div class="item-result text-group-5 item-result-red">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
        <circle cx="50" cy="50" r="44" fill="white"></circle>
        <path d="M32 32 L68 68" stroke="#F23D3D" stroke-width="10" stroke-linejoin="round" />
        <path d="M32 68 L68 32" stroke="#F23D3D" stroke-width="10" stroke-linejoin="round" />
      </svg>
      <p>No</p>
    </div>
  </ng-template>

  <!-- Low -->
  <ng-template #low>
    <div class="item-result text-group-5 item-result-red">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
        <circle cx="50" cy="50" r="44" fill="white"></circle>
        <path d="M50 72V28M28 50L50 30L72 50" stroke="#F23D3D" stroke-width="10" stroke-linejoin="round" transform="rotate(180 50 50)"></path>
      </svg>
      <p>Low</p>
    </div>
  </ng-template>

  <!-- Red -->
  <ng-template #red>
    <div class="item-result text-group-5 item-result-red">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
        <circle cx="50" cy="50" r="44" fill="white" />
        <circle cx="50" cy="50" r="16" fill="#F23D3D" />
      </svg>
      <p>Red</p>
    </div>
  </ng-template>

  <!-- Poor -->
  <ng-template #poor>
    <div class="item-result text-group-5 item-result-red">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
        <circle cx="50" cy="50" r="44" fill="white"></circle>
        <path d="M50 72V28M28 50L50 30L72 50" stroke="#F23D3D" stroke-width="10" stroke-linejoin="round" transform="rotate(180 50 50)"></path>
      </svg>
      <p>Poor</p>
    </div>
  </ng-template>

  <!-- Not Started -->
  <ng-template #notStarted>
    <div class="item-result text-group-5 item-result-red">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
        <circle cx="50" cy="50" r="44" fill="white"></circle>
        <path d="M50 72V28M28 50L50 30L72 50" stroke="#F23D3D" stroke-width="10" stroke-linejoin="round" transform="rotate(180 50 50)"></path>
      </svg>
      <p>Not Started</p>
    </div>
  </ng-template>
}
