@if (workspaceId) {
  <div class="min-h-full">
    <main-menu [workspaceId]="workspaceId" />

    @if (combinedData$ | async; as data) {
      @if (!!data.member && !!data.billing) {
        <!-- <div class="pt-10"> -->
        <main>
          <div [ngClass]="routeLabel === 'fullWidth' ? 'w-full' : 'mx-auto max-w-7xl px-6 pb-10 xl:px-0 pt-10'">
            <router-outlet></router-outlet>
          </div>
        </main>
        <!-- </div> -->
      }
    }
  </div>
}
@if (limitationManagerService.overlay_limitationManager) {
  <limitation-manager (closeLimitationOverlayEmitter)="closeLimitationOverlay()" />
}
