import { NgClass, NgStyle } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { PhotoType } from "src/app/models/photo/photo-type";
import { SinglePhotoUpload } from "src/app/models/photo/photo-upload.model";
import { SiteEnhanced } from "src/app/models/site/site.model";
import { ManageSiteEditDetailsComponent } from "src/app/sections/workspaces/current-workspace/manage-sites/edit/manage-site-edit-details/manage-site-edit-details.component";
import { ManageSiteEditLocationComponent } from "src/app/sections/workspaces/current-workspace/manage-sites/edit/manage-site-edit-location/manage-site-edit-location.component";
import { EmptyListComponent } from "src/app/shared/empty-list/empty-list.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { PhotoDropzoneComponent } from "src/app/shared/photo-dropzone/photo-dropzone.component";
import { PhotoPreviewComponent } from "src/app/shared/photo-preview/photo-preview.component";
import { PhotoUploadSingleComponent } from "src/app/shared/photo-upload-single/photo-upload-single.component";
import { SearchBarComponent } from "src/app/shared/search-bar/search-bar.component";
import { SearchEmptyComponent } from "src/app/shared/search-empty/search-empty.component";

@Component({
  selector: "inspection-details-site-selector",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ManageSiteEditDetailsComponent,
    PhotoDropzoneComponent,
    PhotoPreviewComponent,
    PhotoUploadSingleComponent,
    NgClass,
    LoadingSpinnerComponent,
    NgStyle,
    ManageSiteEditLocationComponent,
    SearchBarComponent,
    SearchEmptyComponent,
    EmptyListComponent,
  ],
  templateUrl: "./inspection-details-site-selector.component.html",
  styleUrl: "./inspection-details-site-selector.component.scss",
})
export class InspectionDetailsSiteSelectorComponent implements OnChanges {
  @Input({ required: true }) inspectionForm!: FormGroup;
  @Input({ required: true }) siteForm!: FormGroup;
  @Input({ required: true }) overlay_siteForm!: boolean;
  @Input({ required: true }) uploadFileArray: SinglePhotoUpload[] = [];
  @Input({ required: true }) overlay_uploadPhotos: boolean = false;
  @Input({ required: true }) photoPreview: boolean = false;
  @Input({ required: true }) newSiteId!: string;
  @Input({ required: true }) workspaceId!: string;
  @Input() sites: SiteEnhanced[] | undefined | null;
  @Input() currentlySelectedSite!: SiteEnhanced | null;
  @Input() allOtherSites: Array<SiteEnhanced> = [];

  @Output() cancelSiteSelectionOutput = new EventEmitter<void>();
  @Output() detectPhotosOutput = new EventEmitter<FileList>();
  @Output() removePhotoFromArrayOutput = new EventEmitter<string>();
  @Output() returnedPhotoOutput = new EventEmitter<PhotoType>();
  @Output() saveSiteOutput = new EventEmitter<FormGroup>();
  @Output() siteSelectionOutput = new EventEmitter<SiteEnhanced>();
  @Output() unselectSiteOutput = new EventEmitter<boolean>();
  @Output() toggleNewSiteFormOutput = new EventEmitter<boolean>();

  public filteredSites: Array<SiteEnhanced> = [];
  public allSitesHidden: boolean = false;
  public searchTerm: string = "";

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["allOtherSites"]) {
      this.applySearch();
    }
  }

  /**
   * On Search
   * @param searchTerm
   */
  onSearch(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.applySearch();
  }

  /**
   * Apply Search
   */
  private applySearch(): void {
    const lowerSearchTerm = this.searchTerm.toLowerCase();
    this.filteredSites = this.allOtherSites.map((site) => ({
      ...site,
      hidden: !site.title.toLowerCase().includes(lowerSearchTerm),
    }));

    this.allSitesHidden = this.filteredSites.every((site) => site.hidden);
  }

  /**
   * Detects photos
   * @param files
   */
  public detectPhotos(files: FileList): void {
    this.detectPhotosOutput.emit(files);
  }

  /**
   * Save the Site
   */
  public saveSite(): void {
    this.saveSiteOutput.emit();
  }

  /**
   * Patch form with photos and save doc
   * @param photos
   */
  public async patchFormWithPhotosAndSaveDoc(photos: PhotoType) {
    this.returnedPhotoOutput.emit(photos);
  }

  /**
   * Remove image from array
   * @param id
   */
  public removePhotoFromArray(id: string): void {
    this.removePhotoFromArrayOutput.emit(id);
  }

  /**
   * Toggle New Site Form
   */
  public toggleNewSiteForm(): void {
    this.toggleNewSiteFormOutput.emit();
  }

  /**
   * Site Selection
   * @param site
   */
  public siteSelection(site: SiteEnhanced): void {
    this.siteSelectionOutput.emit(site);
  }

  /**
   * Unselect Site
   */
  public unselectSite(): void {
    this.unselectSiteOutput.emit();
  }

  /**
   * Cancel Button
   */
  public cancelButton(): void {
    this.cancelSiteSelectionOutput.emit();
  }
}
