<div class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex min-h-screen items-center justify-center text-center">
    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
    <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
    <div class="inline-block h-[675px] w-[785px] transform overflow-hidden rounded-lg bg-gray-50 text-left align-bottom shadow-xl transition-all dark:bg-zinc-800 sm:my-8 sm:align-middle">
      <div class="relative flex h-full flex-1 flex-col justify-between">
        <!-- Transfer loading -->
        @if (transferOwnershipResponseLoadingIndicator) {
          <div class="absolute left-0 top-0 z-10 h-full w-full bg-white bg-opacity-80">
            <div class="flex h-full w-full items-center justify-center">
              <loading-spinner />
            </div>
          </div>
        }

        <!-- Header Title & New -->
        <div class="flex flex-row items-center bg-white px-6 py-6">
          <h3 class="text-base font-semibold text-text_Title" id="modal-title">
            {{ overlay_selectOwner ? "Select New Owner" : "Edit Ownership" }}
          </h3>
        </div>

        <ng-container>
          <div class="flex h-full flex-1 overflow-hidden">
            <div class="w-full space-y-4 overflow-y-scroll overscroll-contain">
              @if (!overlay_selectOwner) {
                <div class="mt-6 grid grid-cols-1 gap-6 px-6 pb-6">
                  <!-- Info Bar -->
                  <info-view
                    [message]="
                      'You are the current owner of ' +
                      workspaceForm.value.name +
                      '.' +
                      '\n\nTransferring the Ownership of your Workspace is immediate and cannot be undone. You will no longer be the Owner of ' +
                      workspaceForm.value.name +
                      'You will be automatically assigned the Admin Role.'
                    "
                    [type]="'warning'" />

                  <!-- Member Selector -->
                  <div class="content-card-wrapper">
                    <div class="content-card-header">
                      <h3>New Workspace Owner</h3>
                    </div>
                    <div class="px-6 py-5">
                      <div class="form-input-wrapper relative flex h-[60px] cursor-pointer items-center hover:ring-gray-400" (click)="toggleNewOwnerPanel()">
                        <div class="text-brand_Primary-hover">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-4 w-4">
                            <path
                              d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
                          </svg>
                        </div>

                        <div class="w-full border-0 pl-3 text-base focus:ring-0 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-700 sm:leading-6" [ngClass]="currentlySelectedMember?.id ? 'text-text_Title' : 'text-text_Placeholder'">
                          {{ currentlySelectedMember?.id ? currentlySelectedMember?.name_first + " " + currentlySelectedMember?.name_last : "Choose New Owner" }}
                        </div>
                        <div class="absolute left-0 top-0 flex h-full w-full items-center justify-end pr-4">
                          <svg xmlns="http://www.w3.org/2000/svg" class="-mr-1 ml-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#C8C8C8" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Confirmation -->
                  <div class="content-card-wrapper">
                    <div class="content-card-header">
                      <h3>Confirmation</h3>
                      <p class="list-meta">To permanently transfer this Workspace, type "TRANSFER" below.</p>
                    </div>
                    <div class="px-6 py-5">
                      <div class="form-input-wrapper">
                        <label for="transfer-confirmation" class="form-input-label">Transfer Ownership Confirmation</label>
                        <input type="text" name="transfer-confirmation" id="transfer-confirmation" class="form-input" placeholder="TRANSFER" [(ngModel)]="transferConfirmation" />
                      </div>
                    </div>
                  </div>
                </div>
              }

              @if (overlay_selectOwner) {
                <manage-workspace-transfer-ownership-members-list
                  [members]="members"
                  [allOtherMembers]="allOtherMembers"
                  [currentlySelectedMember]="currentlySelectedMember"
                  [workspaceForm]="workspaceForm"
                  (memberSelectionOutput)="memberSelection($event)"
                  (toggleNewOwnerPanelOutput)="toggleNewOwnerPanel()" />
              }
            </div>
          </div>

          @if (!overlay_selectOwner) {
            <div class="flex justify-between bg-gray-100 px-6 py-6">
              <button
                type="button"
                class="btn ml-3"
                [ngClass]="{
                  'btn-destructive': transferConfirmation === 'TRANSFER' && currentlySelectedMember?.id,
                  'btn-disabled cursor-not-allowed': transferConfirmation !== 'TRANSFER' || !currentlySelectedMember?.id,
                }"
                [disabled]="transferConfirmation !== 'TRANSFER' || !currentlySelectedMember?.id"
                (click)="transferOwnershipAndCloseOverlays()">
                Transfer Ownership
              </button>

              <div class="flex flex-row-reverse">
                <button type="button" class="btn btn-cancel mr-3" (click)="toggleEditOwnershipOverlay()">Cancel</button>
              </div>
            </div>
          }
        </ng-container>
      </div>
    </div>
  </div>
</div>
