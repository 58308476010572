// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  pwa: true,
  version: "0.3.3",
  loginUrl: "https://checklist-inspector-pro-dev.web.app/loginconfirmation",
  cloudServicesBaseUrl: "https://main-wf57supnuq-ew.a.run.app",
  w3w_api_key: "L876CPW3",
  map_key: "AIzaSyBzqXjkG6PIC-oL9znPRIR0wkC4Xc8Q7qE",
  recaptchaSiteKey: "6LdH9AAgAAAAAHplBmQ4JWG_tpQZGzlNAimk7hrK",
  enableAppCheckDebug: true,
  firebase: {
    projectId: "checklist-inspector-pro-dev",
    appId: "1:310730470913:web:95d6b5b9960a6297ab3200",
    databaseURL: "https://checklist-inspector-pro-dev.firebaseio.com",
    storageBucket: "checklist-inspector-pro-dev.appspot.com",
    locationId: "europe-west",
    apiKey: "AIzaSyAF7rAjadH3yd4Xgo5SXo-_xpKJ7cyxk9A",
    authDomain: "checklist-inspector-pro-dev.firebaseapp.com",
    messagingSenderId: "310730470913",
    measurementId: "G-37RL36CE07",
  },
  col: {
    TIPS: "tips",
    USERS: "users",
    INVITES: "invites",
    WORKSPACES: "workspaces",
    FOLDERS: "folders",
    INSPECTIONS: "inspections",
    CATEGORIES: "categories",
    ITEMS: "items",
    PHOTOS: "photos",
    ACTIONS: "actions",
    SUPPLEMENTARY: "supplementary",
    SIGNATURES: "signatures",
    CLIENTS: "clients",
    SITES: "sites",
    ASSIGNEES: "assignees",
    MEMBERS: "members",
    ROLES: "roles",
    TEMPLATES: "templates",
    TEMPLATE_CATEGORIES: "template_categories",
    TEMPLATE_ITEMS: "template_items",
    THEMES: "themes",
    THEME_STYLES: "theme_styles",
    OPERATIONS: "operations",
    USER_BILLING: "user_billing",
    AUDIT_LOGS: "audit_logs",
    EXPORTS: "exports",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
