import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from "@angular/core";

import { BehaviorSubject, EMPTY, type Observable } from "rxjs";
import { catchError, filter, tap } from "rxjs/operators";

import { LibModule } from "@veamstudios/annotator-sdk";

import { PhotoEnhanced } from "src/app/models/photo/photo.model";
import { BreadcrumbsComponent } from "src/app/shared/breadcrumbs/breadcrumbs.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { PhotoDetailService } from "../services/photo-detail.service";

@Component({
  selector: "annotations-wrapper",
  standalone: true,
  imports: [AsyncPipe, LibModule, LoadingSpinnerComponent, BreadcrumbsComponent],
  templateUrl: "./annotations-wrapper.component.html",
  styles: [
    `
      :host {
        @apply w-full flex flex-col;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnotationsWrapperComponent implements OnInit {
  @Input() set workspaceId(value: string) {
    this._workspaceId = value;
  }

  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input() set folderId(value: string) {
    this._folderId = value;
  }

  get folderId(): string {
    return this._folderId;
  }

  @Input() set inspectionId(value: string) {
    this._inspectionId = value;
  }

  get inspectionId(): string {
    return this._inspectionId;
  }

  @Input() set photoId(value: string) {
    this._photoId = value;
  }

  get photoId(): string {
    return this._photoId;
  }

  @Input() set categoryId(value: string) {
    this._categoryId = value;
  }

  get categoryId(): string {
    return this._categoryId;
  }

  @Input() set itemId(value: string) {
    this._itemId = value;
  }

  get itemId(): string {
    return this._itemId;
  }

  public photoDoc$!: Observable<PhotoEnhanced>;

  public _workspaceId!: string;
  public _folderId!: string;
  public _inspectionId!: string;
  public _photoId!: string;
  public _categoryId!: string;
  public _itemId!: string;
  private readonly showSpinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private photoDetailService = inject(PhotoDetailService);

  public ngOnInit(): void {
    const path = this.photoDetailService.getPhotoPath(this.workspaceId, this.inspectionId, this.photoId);

    this.photoDoc$ = this.photoDetailService.getPhotoDoc$(path).pipe(
      tap(console.warn),
      filter((data) => !!data),
      catchError((error: Error) => {
        alert(error.message);

        return EMPTY;
      })
    );
  }

  public get annotationsPath(): string {
    const path = this.photoDetailService.getPhotoPath(this.workspaceId, this.inspectionId, this.photoId);

    return `${path}/annotations`;
  }

  public get showSpinner$(): Observable<boolean> {
    return this.showSpinner.asObservable();
  }

  public handleSave(blob: Blob): void {
    this.showSpinner.next(true);

    const path = this.photoDetailService.getPhotoPath(this.workspaceId, this.inspectionId, this.photoId);

    this.photoDetailService
      .saveAnnotationsAsPng(path, this.photoId, blob)
      .catch(console.error)
      .finally(() => {
        this.showSpinner.next(false);
      });
  }
}
