import { AsyncPipe, NgClass } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { User } from "cip";
import { filter, Observable, tap } from "rxjs";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { ToolTipsStringService } from "src/app/core/services/strings/tooltips.service";
import { IncorrectFileType } from "src/app/models/photo/photo-incorrect-file";
import { PhotoType } from "src/app/models/photo/photo-type";
import { SinglePhotoUpload } from "src/app/models/photo/photo-upload.model";
import { SiteEnhanced } from "src/app/models/site/site.model";
import { ToolTipFilter, ToolTipType } from "src/app/models/strings/strings.model";
import { SiteDetailService } from "src/app/sections/workspaces/current-workspace/manage-sites/services/site-detail.service";
import { SitesListService } from "src/app/sections/workspaces/current-workspace/manage-sites/services/sites-list.service";
import { TooltipComponent } from "src/app/shared/tooltip/tooltip.component";
import { InspectionDetailsSiteSelectorComponent } from "../inspection-details-site-selector/inspection-details-site-selector.component";

@Component({
  selector: "inspection-details-site-wrapper",
  standalone: true,
  imports: [TooltipComponent, ReactiveFormsModule, NgClass, InspectionDetailsSiteSelectorComponent, AsyncPipe],
  templateUrl: "./inspection-details-site-wrapper.component.html",
  styleUrl: "./inspection-details-site-wrapper.component.scss",
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionDetailsSiteWrapperComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) user!: User;
  @Input({ required: true }) inspectionForm!: FormGroup;

  // Services
  private toolTipsStringService = inject(ToolTipsStringService);
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private sitesListService = inject(SitesListService);
  private siteDetailService = inject(SiteDetailService);
  public toolTipInspectionDetailsSite: ToolTipFilter = this.toolTipsStringService.getToolTip(ToolTipType.InspectionDetailsSite);

  // Site Options
  public currentlySelectedSite!: SiteEnhanced | null;
  public allOtherSites: Array<SiteEnhanced> = [];

  public sites$!: Observable<SiteEnhanced[]>;
  public siteForm: FormGroup;
  public overlay_siteForm = false;

  public overlay_siteSelector!: boolean;

  // Photos
  public uploadFileArray: SinglePhotoUpload[] = [];
  public incorrectFileTypeArray: IncorrectFileType[] = [];
  public photoPreview: boolean = false;
  public overlay_uploadPhotos = false;
  private tempBlobPhotoURL!: string;
  public newSiteId!: string;

  constructor() {
    this.siteForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      title: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      ref: ["", this.formFormattingService.noWhitespaceValidator()],
      address: ["", this.formFormattingService.noWhitespaceValidator()],
      map_url: null,
      image_url: null,
      image_thumbnail_url: null,
      what_3_words: null,
      longitude: null,
      latitude: null,
      created_by: null,
      created_by_id: null,
    });
    this.currentlySelectedSite = null;
  }

  /**
   * Get Sites List
   */
  private async getSitesList(): Promise<void> {
    this.sites$ = this.sitesListService.getSitesList$(this.workspaceId).pipe(
      filter((data) => !!data),
      tap((data) => {
        this.allOtherSites = [];
        this.currentlySelectedSite = null;
        data.forEach((element) => {
          if (element.id === this.inspectionForm.value["site_id"]) {
            this.currentlySelectedSite = element;
          } else {
            this.allOtherSites.push(element);
          }
        });
      })
    );
  }

  /**
   * Save Site
   */
  private async saveSite(): Promise<void> {
    try {
      const site = await this.siteDetailService.setSiteDoc(this.workspaceId, this.newSiteId, this.siteForm.value, "added", this.user);
      this.siteSelection(site as SiteEnhanced);
      this.toggleSiteFormOverlay();
      this.togglePhotosUploadOverlay();
      this.emptyUploadFileArray();
      this.revokeObjectURL();
      this.siteForm.reset();
      this.newSiteId = "";
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Detect Photos
   * @param files
   */
  public async detectPhotos(files: FileList): Promise<void> {
    const file = files[0];
    const fileType = file.type;
    const fileName = file.name;
    const fileExtension = fileType.split("/").pop();
    if (!(fileExtension == "jpg" || fileExtension == "jpeg" || fileExtension == "png")) {
      const incorrectFile: IncorrectFileType = { title: fileName };
      this.incorrectFileTypeArray.push(incorrectFile);
    } else {
      this.uploadFileArray = [];
      this.togglePhotoPreview();
      this.siteForm.markAsDirty();
      this.tempBlobPhotoURL = URL.createObjectURL(file);
      const newId = this.firestoreUtilsService.createFirestoreId();
      this.uploadFileArray.push({
        id: newId,
        photo: this.tempBlobPhotoURL,
      });
    }
  }

  /**
   * Site Selection
   * @param site
   */
  public siteSelection(site: SiteEnhanced): void {
    this.inspectionForm.patchValue({
      site_id: site.id,
      site_title: site.title,
      site_photo_thumbnail_url: site.image_thumbnail_url,
    });
    this.currentlySelectedSite = site;
    this.inspectionForm.markAsDirty();
    this.overlay_siteSelector = false;
    this.getSitesList();
  }

  /**
   * Unselect Site
   */
  public unselectSite(): void {
    this.inspectionForm.patchValue({
      site_id: null,
      site_title: null,
      site_photo_thumbnail_url: null,
    });
    this.currentlySelectedSite = null;
    this.inspectionForm.markAsDirty();
    this.getSitesList();
  }

  /**
   * Toggle Site Selection
   */
  public async toggleSiteSelectionOverlay(): Promise<void> {
    // Toggle the overlay state
    this.overlay_siteSelector = !this.overlay_siteSelector;

    // If the sites$ observable is not yet populated, populate it
    if (!this.sites$) {
      this.getSitesList();
    }

    // If the overlay is now visible, create a new site ID
    if (this.overlay_siteSelector) {
      this.newSiteId = this.firestoreUtilsService.createFirestoreId();
    }
  }

  /**
   * Remove Photo From Array
   * @param id
   */
  public removePhotoFromArray(id: string): void {
    const indexOfObject = this.uploadFileArray.findIndex((object) => {
      return object.id === id;
    });
    const file = this.uploadFileArray.splice(indexOfObject, 1);
    this.togglePhotoPreview();
    URL.revokeObjectURL(file[0].photo);
    if (this.uploadFileArray.length < 1) {
      this.photoPreview = false;
    }
  }

  /**
   * Patch Form With Photos And Save Doc
   * @param photos
   */
  public async patchFormWithPhotosAndSaveDoc(photos: PhotoType) {
    this.siteForm.patchValue({
      image_url: photos.image_url,
      image_thumbnail_url: photos.image_thumbnail_url,
    });
    try {
      this.saveSite();
    } catch (error) {
      alert(error);
    }
    this.toggleSiteFormOverlay();
    this.togglePhotosUploadOverlay();
    this.emptyUploadFileArray();
    this.revokeObjectURL();
  }

  /**
   * Does A Photo Need Uploading
   */
  public doesAPhotoNeedUploading(): void {
    if (this.uploadFileArray.length > 0) {
      this.togglePhotosUploadOverlay();
    } else {
      this.saveSite();
    }
  }

  /**
   * Toggle Photo Preview
   */
  private togglePhotoPreview(): void {
    this.photoPreview = !this.photoPreview;
  }

  /**
   * Toggle Site Form Overlay
   */
  public toggleSiteFormOverlay(): void {
    this.overlay_siteForm = !this.overlay_siteForm;
  }

  /**
   * Toggle Photos Upload Overlay
   */
  public togglePhotosUploadOverlay(): void {
    this.overlay_uploadPhotos = !this.overlay_uploadPhotos;
  }

  /**
   * Empty Upload File Array
   */
  private emptyUploadFileArray(): void {
    this.uploadFileArray = [];
  }

  /**
   * Revoke Object URL
   */
  private revokeObjectURL(): void {
    URL.revokeObjectURL(this.tempBlobPhotoURL);
  }
}
